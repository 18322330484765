import React, { Component } from 'react';
import MenuItem from 'material-ui/MenuItem';
import Menu from 'material-ui/Menu';
import {connect} from 'react-redux';
import DefaultPopover from './DefaultPopover';
import DropDownIco from 'material-ui/svg-icons/navigation/arrow-drop-down';
import s from './ChannelStatus.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import NarrowSelect from './../NewInputs/NarrowSelect';
import { changePositionStatus } from './../../actions/monitor';
import cx from 'classnames';
import PropTypes from 'prop-types';
import PubSub from 'pubsub-js';
import BlurIfNoSubscription from './../Signup/BlurIfNoSubscription';

export const findAllIndexes = (field, arr, id) => {
  return arr.reduce((acc, item, i) => {
    if (item?.[field] === id) {
      return acc.concat(i)
    };
    return acc;
  }, []);
}

export function updateVesselStatusHelper(arr, data) {
  if (!data || !data.vesselId) return arr;
  const { vesselId, vesselGroups } = data;
  const vesselIndexes = findAllIndexes('_id', arr, vesselId);
  if (vesselIndexes.length === 0) return arr;
  const newRows = arr.map((row, i) => {
    if (vesselIndexes.includes(i)) {
      row = {
        ...row,
        vesselUserGroups: {
          ...row.vesselUserGroups,
          ...vesselGroups
        }
      }
    }
    return row;
  });
  return newRows;
}

export function updateChannelStatusHelper(arr, data) {
  if (!data || !data.positionId) return arr;
  const { positionId, positionStatus } = data;
  const positionIndexes = findAllIndexes('vesselRequestId', arr, positionId);
  console.log(positionIndexes);
  if (positionIndexes.length === 0) return arr;
  const newRows = arr.map((row, i) => {
    if (positionIndexes.includes(i)) {
      row = {
        ...row,
        channelUserStatus: {
          ...row.channelUserStatus,
          ...positionStatus
        }
      }
    }
    return row;
  });

  return newRows;
}
export class ChannelStatus extends Component {

  state = {
    open: false,
    anchor: null
  }

  static defaultProps = {
    name: 'userStatus',
    value: "",
    dontShowUnderline: false,
    handleChange: item => item,
    labelClass: "",
    select: false
  }

  static statuses = {
    "open": {
      name: 'Open',
      status: 'open'
    },
    "period": {
      name: 'Open (Period)',
      status: 'period',
      isDisabled: props => props?.vesselStatuses?.(props.vessel)?.find(this.findGroup("liner"))
    },
    "time_charter": {
      name: 'Open (TC)',
      status: 'time_charter'
    },
    "voyage": {
      name: 'Open (Voyage)',
      status: 'voyage'
    },
    "part_cargo": {
      name: 'Open (Part Cargo)',
      status: 'part_cargo',
      isDisabled: props => props?.vesselStatuses?.(props.vessel)?.find(this.findGroup("pt")) || props.vessel?.deadWeight?.sDWT > 52000
    },
    "fixed": {
      name: 'Fixed',
      status: 'fixed'
    },

  }

  static blurredStatus = {
    name: "****",
    status: "****"
  }


  static convertIfBlurred = status => {
    const regexp = /\*/gi;
    if (regexp.test(status)) {
      return true;
    }
    return false;
  }

  static findGroup = vesselStatus => group => String(group).toLowerCase() === String(vesselStatus).toLowerCase()

  mapStatus = status => {
    const { blurredStatus, statuses } = this.constructor;
    if (this.checkIfBlurred()) {
      return blurredStatus;
    }
    const mappedStatus = statuses[Object.keys(statuses).find(s => s === (status?.status || status))] || statuses.open;
    return mappedStatus;
  }
  renderStatuses = (statuses, handler) => {
    const { mapStatus } = this;
    statuses = statuses || this.constructor.statuses;
    const status = mapStatus(this.props.value);
    const statusesArr = Object.keys(statuses);
    return statusesArr.map(el => {
      const item = statuses[el];
      const isDisabled = item.isDisabled && this.props.vessel ? item.isDisabled(this.props) : false;
      return (
        <MenuItem disabled={isDisabled} className={cx(s.menu_item, item.status === status?.status ? s.active : '')} onClick={handler && !isDisabled ? handler.bind(this, item) : undefined} key={item.status} value={item.status} primaryText={item.name}></MenuItem>
      )
    })
  }

  renderStatusesWithExceptions = (exceptions, ...rest) => {
    const { statuses } = this.constructor;
    let statusesWithExceptions = { ...statuses };
    if (exceptions?.length) {
      exceptions.forEach(exception => {
        if (statusesWithExceptions[exception]) {
          delete statusesWithExceptions[exception];
        }
      })
    }
    return this.renderStatuses(statusesWithExceptions, ...rest);
  }

  openMenu = ev => {
    if (this.checkIfBlurred()) return;
    ev.preventDefault();
    ev.stopPropagation();
    this.setState({
      open: true,
      anchor: ev.currentTarget
    })
  }
  closeMenu = () => {
    this.setState({
      open: false,
    })
  }

  checkIfBlurred = () => this.props.isBlurred

  handleChange = el => {
    if (this.checkIfBlurred()) return;
    console.log(el);
    const { handleChange } = this.props;
    this.setState({
      open: false
    });
    handleChange && handleChange(el, this.props.vessel._id);
    const info = {
      vesselId: this.props.vessel._id,
      vesselRequestId: this.props.channelId,
      status: el
    }
    const newStatus = this.generateNewStatusEvent(info);
    this.props.changePositionStatus(newStatus);
    // update status for relevant vessels in main deck list ( because VesselTableOpenCargo component doesn't look at Redux, it's keeping its own state for list. )
    // PubSub.publish("userStatus", newStatus)
  }

  generateNewStatusEvent = info => {
    const positionId = info?.vesselRequestId || this.props.channelId;
    const vesselId = info?.veselId || this.props.vessel._id;
    const positionStatus = {
      setAt: new Date(),
      setBy: this.props.user._id,
      status: info.status
    }
    return {
      positionId,
      vesselId,
      positionStatus
    }
  }

  renderSelect = () => {
    let { value, dontShowUnderline, name, handleChange, useDefaultValue, floatingLabelText = "Select" } = this.props;
    const underline = dontShowUnderline ? {display: 'none'} : undefined;
    const { mapStatus } = this;

    const status = value ? mapStatus(value) : "";
    return (
      <div className={s.wrapper}>
        <NarrowSelect autoWidth floatingLabelText={floatingLabelText} onChange={(ev, val) => handleChange(val)} underlineStyle={underline} underlineFocusStyle={underline} value={status?.status || ""} name={name} fullWidth >
            {
              this.renderStatusesWithExceptions(['fixed'])
            }
        </NarrowSelect>
      </div>
    )
  }

  renderPopover = () => {
    const {anchor, open} = this.state;
    let { value, children, label, labelClass, labelStyle = {}, popoverClass, floatingLabelText = "Select", ...rest} = this.props;
    const { statuses, mapStatus } = this;

    const status = mapStatus(value);
    const { handleChange } = this;

    const labelText = status?.name || floatingLabelText;
    const defaultLabel = (
      <div className={cx(s.default_label, labelClass)} style={labelStyle}>
        <span className={s.channel_label}>{labelText}</span>
        <DropDownIco color="#FFFFFF" style={{width: 18, height: 18, minWidth: 18, minHeight: 18, transform: `scale(1.3)`}}></DropDownIco>
      </div>
    )
    label = label || defaultLabel;
    return (

        <div title={labelText} style={{width: '100%'}} onClick={this.openMenu}>
          {
            label
          }
          <DefaultPopover
            open={open}
            anchor={anchor}
            handleClose={this.closeMenu}
            classes={popoverClass}
            {...rest}
          >
            <Menu value={status?.status || ""}>
              {
                this.renderStatuses(statuses, handleChange)
              }
            </Menu>
          </DefaultPopover>
        </div>
    )
  }

  render() {
    const {  select } = this.props;

    const element = select ? this.renderSelect() : this.renderPopover();
    return element
  }
}

export default connect(state => ({user: state.login.user}), { changePositionStatus })(withStyles(s)(ChannelStatus))
