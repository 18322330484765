
import React from 'react';
import RaisedButton from './RaisedButton';
import OpenInNewTabIcon from '../Icons/OpenInNewTabIcon';

export function OpenInNewTabButton({ cargo, title, to }) {
  return (<a
    href={to}
    target="_blank"
    style={{
      display: 'inline-block',
      height: '100%',
    }}
  >
    <RaisedButton
      primary
      styles="xs"
      label={<span>
              <OpenInNewTabIcon
                style={{
                  marginRight: '6px',
                }}
              />
              {title}
            </span>}
      style={{
        minWidth: 'auto',
        borderRadius: '15px',
        marginRight: '8px',
      }}
      buttonStyle={{
        background: 'transparent',
        border: '1px solid #D2D2D2',
        borderRadius: '15px',
      }}
      labelStyle={{
        height: '100%',
        padding: '0 12px',
        alignItems: "center",
        fontSize: '11px',
        fontWeight: '700',
        textTransform: 'uppercase',
        color: '#4380C7',
        display: 'flex',
      }}
    />
  </a>);
}

export default OpenInNewTabButton;
