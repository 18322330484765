import React from "react";
import MainDeckIcon from "../Icons/MainDeckIcon";
import ChatMailIcon from "../Icons/ChatMailIcon";
import ContactBookIcon from "../Icons/ContactBookIcon";
import Cargo from "../Icons/Cargo";
import Ship from "../Icons/Ship";
import DocumentsIcon from "../Icons/DocumentsIcon";
import Calculator from "../Icons/IconCalculator";
import ChartIcon from "../Icons/ChartIcon";
import PortIcon from "../Icons/Port";
import Settings from "../Icons/Settings";

export const naviagtionItems = [
  {
    title: "Trading Desk",
    route: "/main-deck",
    path: "/main-deck",
    icon: <MainDeckIcon />,
    userSensetive: false
  },
  {
    title: "Exchange",
    route: "/emails/-/-/---/-/-",
    path: "/emails",
    icon: <ChatMailIcon fill="#fff" />,
    userSensetive: false
  },
  {
    title: "Contacts",
    route: "/contacts/-/-",
    path: "/contacts",
    icon: <ContactBookIcon fill="#fff" />,
    userSensetive: false
  },
  {
    title: "Cargo",
    route: "/my/cargo",
    path: "/my/cargo",
    icon: <Cargo fill="#fff" opacity={1} />,
    userSensetive: true
  },
  {
    title: "Fleet",
    route: "/fleetmonitor",
    path: "/fleetmonitor",
    icon: <Ship opacity={1} fill="#fff" />,
    userSensetive: false
  },
  {
    title: "Documents",
    route: "/contracts",
    path: "/contracts",
    icon: <DocumentsIcon fill="#fff" opacity={1} />,
    userSensetive: true
  },
  {
    title: "Calculator",
    route: "/calculator/-/-/-",
    path: "/calculator",
    icon: <Calculator fill="#fff" opacity={1} />,
    userSensetive: true
  },
  {
    title: "Markets",
    route: "/markets",
    path: "/markets",
    icon: <ChartIcon fill="#fff" opacity={1} />,
    userSensetive: true
  },
  {
    title: "Ports",
    route: "/port",
    path: "/port",
    icon: <PortIcon fill="#fff" opacity={1} />,
    userSensetive: false
  }
];

export const settingsItem = {
  title: "Settings",
  route: "/settings/profile",
  path: "/settings",
  icon: <Settings fill="#fff" opacity={1} />,
  userSensetive: false
};
