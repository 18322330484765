import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import s from './Port.scss';
import Formsy from 'formsy-react';
import SearchInput from '../Common/SearchInput';
import RaisedButton from '../Common/RaisedButton';
import Table from '../Common/Table';
import Link from '../Link';
import { connect } from 'react-redux';
import * as portActions from '../../actions/port';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import history from '../../core/history';
import PortApi from '../../core/api/Port';
import { PortInfo } from '../Public/PublicPort';
import EditPort from './EditPort';
import EmptyListResult from '../Common/EmptyListResults';
import Loader from '../Common/Loader';



class PortListSection extends PureComponent {
  static propTypes = {
    getList: PropTypes.func,
    portList: PropTypes.object,
    baseUrl: PropTypes.string,
    addPortEnabled: PropTypes.boolean,
    portId: PropTypes.string,
    action: PropTypes.string,
  };

  static defaultProps = {
    baseUrl: '/port/',
    addPortEnabled: true,
  }

  cols = [
    {
      name: 'name',
      label: 'Name',
      style: { flex: '1' },
      renderer: (col, row) => <Link className={s.port_name} key={1}
                                    to={`${this.props.baseUrl}${row.sefName || row._id}`}>{row.name}</Link>,
    },
    {
      name: 'unLoCode',
      label: 'un LoCode',
      style: { width: '70px' },
    }, {
      name: 'country.name',
      label: 'Country',
      renderer: (col, row) => row.country.name,
      style: { flex: '1' },
    },
    {
      name: 'plannedCargoes',
      label: 'Planned Cargoes',
      style: { width: '110px', justifyContent: 'flex-end' },
    },
  ];

  componentDidMount() {
    if (!this.props.portList.page) {
      this.props.getList(this.props.portList);
    }
    if (this.props.portId) {
      this.fetchPort(this.props.portId);
    }
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.portId !== this.props.portId) {
      if (nextProps.portId) {
        this.fetchPort(nextProps.portId);
      } else if (this.props.portId) {
        this.clearSelectedPort();
      }
    }
  }

  fetchPort = async (id) => {
    const res = await PortApi.getPublic(id);
    if (!res.data) {
      throw new Error(`port ${id} not found`);
    }
    this.setState({ selectedPort: res.data });
  }

  clearSelectedPort = () => {
    this.setState({ selectedPort: null });
  }


  handleLoadMore = (filters, sort) => {
    const portList = { ...this.props.portList };
    if (filters) {
      portList.filters = { ...filters };
      portList.page = 0;
      portList.next = 1;
    }
    if (sort) {
      portList.sort = { ...sort };
      portList.next = 1;
    }
    return this.props.getList(portList);
  };

  handleSearch = (query) => {
    this.handleLoadMore({ ...this.props.portList.filters, query });
  };

  onKeyPress(event) {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  }


  handleRowSelected = (port) => {
    history.push(`${this.props.baseUrl}${port.sefName || port._id}`, undefined, undefined, {saveSearchParams: true});
  }
  handleOpenAddPort = () => {
    history.push(`${this.props.baseUrl}create`, undefined, undefined, {saveSearchParams: true});
  }

  render() {
    return (
      <div className={s.port_list}>
        <Formsy.Form onKeyPress={this.onKeyPress} ref="form">
          <div className={s.filters}>
            <div className="flex align-items-center">{this.props.renderTabs ? this.props.renderTabs() : null}
              <div className={s.search}>
                <SearchInput query={this.props.portList.filters.query} handleSearch={this.handleSearch}
                             placeholder={'Enter port or Un Locode'} name="input"/>
              </div>
            </div>
            {
              this.props.addPort || !this.props.user || !this.props.addPortEnabled ?
                null :
                <RaisedButton
                  label="ADD PORT"
                  onClick={this.handleOpenAddPort}
                  disableTouchRipple
                  disableFocusRipple
                  primary
                  type="submit"
                  style={{ minWidth: '80px' }}
                  buttonStyle={{
                    width: '80px',
                    height: '28px',
                    lineHeight: '26px',
                  }}
                  labelStyle={{
                    padding: '0',
                    fontSize: '11px',
                    fontWeight: 'bold',
                    fontFamily: 'Roboto Condensed',
                  }}
                />
            }
          </div>
        </Formsy.Form>
        {this.props.portList.loading ? <div style={{position: 'relative', minHeight: '100%'}}><Loader /></div> : (this.props.portList.filters.country || this.props.portList.filters.query) && !this.props.portList.loading && this.props.portList.data.length === 0 ?
          <EmptyListResult onClear={this.handleLoadMore.bind(this, { country: undefined, query: undefined })} />
          : <Table
            id="portListTable" {...this.props.portList} handleLoadMore={this.handleLoadMore} cols={this.cols}
            handleRowSelected={this.handleRowSelected}
            baseUrl={this.props.baseUrl}
          />}
        {this.props.portId && <div className={s.port_info_inside_list_wrapper}>
          <PortInfo baseUrl={this.props.baseUrl} portId={this.props.portId} user={this.props.user} searchEnabled={false} editEnabled={true} edit={this.props.action === 'edit'} />
        </div>}
      </div>
    );
  }
}
const mapStateToProps = function(state) {
  return {
    ...state.port,
    user: state.login.user
  }
}

export default connect(mapStateToProps, portActions)(
  withStyles(s)(PortListSection),
);
