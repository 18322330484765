import PropTypes from 'prop-types';
import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import history from '../../core/history';
import s from './Port.scss';
import sg from './General.scss';
import Page from '../Page/Page';
import Dialog from '../Common/Dialog';
import RaisedButton from '../Common/RaisedButton';
import cx from 'classnames';
import ContentEditor from 'material-ui/svg-icons/editor/mode-edit';
import Formy from 'formsy-react';
import { connect } from 'react-redux';
import * as portActions from '../../actions/port';
import FormsyText from '../Common/FormsyText';
import Select from '../Common/Select';
import MenuItem from 'material-ui/MenuItem';
import AvailableLiftCranes from './AvailableLiftCranes';
import ButtonForm from './ButtonForm';
import Diff from '../Common/Diff';
import CustomCheckbox from '../Common/CustomCheckbox';
import CheckBox from '../Common/CheckBox';
import ServiceProviders from './ServiceProviders';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import EditIcon from 'material-ui/svg-icons/image/edit';
import CheckIcon from 'material-ui/svg-icons/navigation/check';
import BlockIcon from 'material-ui/svg-icons/content/block';
import RestoreIcon from '../Icons/Restore';
import ConfirmDialog from '../Common/ConfirmDialog';

import {
  availableFacilities,
  CRANE_GROUPS,
  floatingLabelFocusStyle,
  floatingLabelShrinkStyle,
  floatingLabelStyle,
  inputStyle,
  restrictions,
  timezoneList,
  WAREHOUSES,
} from './settings';
import { clearEmptyStrings, normalizeUrl, sortObject } from '../../core/utils';
import { dateTime } from '../../core/format';
import PortHeader from './PortHeader';
import Link from '../Link/Link';
import { port } from '../../config';

const Form = Formy.Form;

const labelStyle = { fontSize: '12px', lineHeight: '12px', color: '#999999', top: '46px', transform: 'scale(1) translate(0px, 0px)' };
const labelShrinkStyle = { transform: 'scale(1) translate(0px, -20px)' };

class EditPort extends Component {
  static PropTypes = {
    portId: PropTypes.string,
    getChanges: PropTypes.func,
    updatePort: PropTypes.func,
    upsertProvider: PropTypes.func,
  };
  static defaultProps = {
    profile: { phones: [] },
  };
  static contextTypes = {
    showMessage: PropTypes.func,
    blockTransition: PropTypes.func,
  }

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isEditSite: false,
      iconGreen: false,
      isEditDescription: false,
      dirty: false
    };
		//console.log('edit port props', props)
  }

  componentDidMount() {
    this.props.getChanges(this.props.portId).then((res) => {
      if (res.status === 200) {
        //console.log("respond data", res.data)
        let port;
        let originPort;
        if (res.data.history) {
			    res.data.history.changedValues.portLimitations = res.data.history.changedValues.portLimitations || {};
          res.data.history.originalValues.portLimitations = res.data.history.originalValues.portLimitations || {};
          port = { liftsCranes: {}, serviceProviders: [], portLimitations: {}, warehouses: {}, ...res.data, ...res.data.history.changedValues, history: undefined };
          originPort = { liftsCranes: { }, serviceProviders: [], portLimitations: {}, warehouses: {}, ...res.data, ...res.data.history.originalValues, history: undefined };
        } else {
          port = { liftsCranes: { }, serviceProviders: [], portLimitations: {}, warehouses: {}, ...res.data };
          originPort = { liftsCranes: { }, serviceProviders: [], portLimitations: {}, warehouses: {}, ...JSON.parse(JSON.stringify(res.data)) };
        }
        port.site = port.site ?? "";
        originPort.site = originPort.site ?? "";
        //originPort.portLimitations =  clearEmptyStrings(originPort.portLimitations)
        Object.keys(originPort.portLimitations).forEach((key) => {
          if (originPort.portLimitations[key] === ('' || 0)) {
            originPort.portLimitations[key] = undefined;
            return;
          }
        })
        //originPort.portLimitations =  Object.fromEntries(Object.entries(originPort.portLimitations).forEach(([key, value]) => value ?? undefined))

        let cleanPort = { ...port };
        cleanPort.facilities.csiComplaint = undefined;
        cleanPort.facilities.ispsComplaint = undefined;
        cleanPort.serviceProviders = cleanPort.serviceProviders.map((sp) => {
          if (sp.history) {
            sp = { ...sp, ...sp.history[0].changedValues };
          }
          return { ...sp, history: undefined, __v: undefined };
        }
          );
        cleanPort.site = cleanPort.site || undefined;
        cleanPort.timezone = cleanPort.timezone || undefined;
        cleanPort = sortObject(cleanPort);
        this.setState({
          rPort: port,
          port,
          originPort,
          cleanPort: JSON.stringify(cleanPort),
          history: res.data.history,
          loading: false,
        });
      }
    });


  }


  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      this.unblock && this.unblock();
    }
  }

  handleEditLocation = (newValues = {}) => {
    if (newValues.currentTarget) {
      newValues = {};
    } else {
      newValues.unLoCode = newValues.unLoCode.toLocaleUpperCase();
    }
    this.setState({
      port: { ...this.state.port, ...newValues },
    });
  };

  handleEditDescription = (e, { additionalDescription } = {}) => {
    if (additionalDescription !== undefined) {
      this.state.port = { ...this.state.port, additionalDescription };
    }
    this.setState({isEditDescription: !this.state.isEditDescription});
  };

  handleRestoreOriginDescription = () => {
    this.setState({
      port: {
        ...this.state.port,
        additionalDescription: this.state.rPort?.additionalDescription ?? ""
      }
    })
  }

  handleEditSite = (newValue) => {
    if (!this.state.isEditSite){
       this.setState({isEditSite: true})
    }
    else if (this.state.isEditSite) {
      const newSite = newValue.site
      this.setState({
        isEditSite: false,
        port: {
          ...this.state.port, site: newSite
        }
      })
    }
  }

  handleChangeIconColor = (e)  => {
    if (e.target.value !== this.state.port.site) {
      this.setState({
        iconGreen: true
      })
    } else {
      this.setState({
        iconGreen: false
      })
    }
  }

  handleRestoreOriginSite = () => {
    this.setState({
      port: {
        ...this.state.port,
        site: this.state.rPort?.site ?? ""
      }
    })
  }

  handleResetSite = () => {
    this.setState({
      port: {
        ...this.state.port,
        site: ""
      },
      // originPort: {
      //   ...this.state.originPort,
      //   site: ""
      // }
    })
  }

  handleAddConfirmModal = () => {
    if (!this.state.dirty && typeof window !== 'undefined') {
      this.setState({dirty: true})
      this.unblock = this.context.blockTransition('Are you sure you want to leave port modification page?', (location, action) => {
        if (location.pathname.indexOf(`/${this.props.portRouteName}/edit`) !== -1) {
          return false;
        }
        return true;
      });
    }
  }

  handleSave = () => {
    const isEditSp = this.refs.serviceProvidersForm.isChanged();
    if (isEditSp) {
      this.context.showMessage({
        level: 'error',
        message: '"service providers" not saved',
      });
      return;
    }

    const liftsCranes = {};
    for (let i = 0; i < CRANE_GROUPS.length; i++) {
      const key = CRANE_GROUPS[i];
      liftsCranes[key] = this.refs[key].getModel();
    }
    const newPort = {
      ...this.state.port,
      portLimitations: clearEmptyStrings(this.refs.restrictionsForm.getModel()),
      facilities: this.refs.facilitiesForm.getModel(),
      warehouses: this.refs.warehousesForm.getModel(),
      serviceProviders: this.refs.serviceProvidersForm.getModel(),
      liftsCranes,
    };
    const clean = JSON.stringify(sortObject(newPort)) === JSON.stringify(sortObject(this.state.originPort));
    if (clean) {
      this.context.showMessage({
        level: 'error',
        message: 'There are no changes',
      });
      return;
    }
    // return;
    this.setState({ loading: true });
    this.props.updatePort(newPort).then((res) => {
      this.setState({ loading: false });
      if (res.status === 200) {
        this.setState({ port: null, dirty: false });
        this.unblock?.();
        this.componentDidMount();
        //document.querySelector('.' + s.root).scrollIntoView();
      }
    });
  };

  handleSendModification = () => {
    this.refs.restrictionsForm.submit();
  };

  render() {
    const port = this.state.port;
    const originPort = this.state.originPort;
    const rPort = this.state.rPort;
    //console.log(this.state.dirty);
    const portRouteName = this.props.portRouteName;

    const renderSite = () => {
      let originSite = '';
      if (originPort.site) {
        originSite = originPort.site.includes('http' || 'https') ? originPort.site : "//" + originPort.site;
      }
      if (port.site === originPort.site) {
        return <a className={s.linkSite} href={originSite} target="_blank">{originPort.site}</a>
      } else {
        return <span>
        <span className={s.originSite}>{originPort.site}</span>
        <span className={s.site}>{port.site}</span>
      </span>
      }
    }

    if (!port) {
      return null;
    }
    return (
      <div className={s.root}>
        {this.state.confirmTransitionDialog
          ? this.state.confirmTransitionDialog
          : null}
        {port ?
          <div style={{height: '100%'}}>
            <PortHeader
              title="Edit port"
              borderLeft
              modification = {
                this.state.history && this.state.history.status === 'new' ?
                <div className={s.modification}>
                  Modifications sent at {dateTime(this.state.history.updateAt)}, waiting for approval.
                </div>
                : null
              }
              right=
              {<div style={{display:'flex', alignItems: 'center'}}>
                <RaisedButton
                  label="Send Modification"
                  className={s.button_edit}
                  onClick={this.handleSendModification}
                  disabled={this.state.loading}
                  primary
                  styles={'xs'}
                />
                <Link to={`${this.props.baseUrl}${portRouteName}`} saveSearchParams><CloseIcon /></Link>
              </div>
              }
            />
            <div className={s.port_edit}>
              <div className={s.edit_wrapper}>
                {this.state.isEditSite ?
                  <Form className={cx(s.edit_container, s.flex)}
                    onValidSubmit={this.handleEditSite}
                    ref="form" style={{width: '100%', borderBottom: 'none'}}
                    onChange={() => this.handleAddConfirmModal()}
                  >
                    <FormsyText
                      floatingLabelText="Site"
                      validations="isSite"
                      validationError="Please enter valid url"
                      name="site"
                      floatingLabelStyle={labelStyle}
                      floatingLabelShrinkStyle={labelShrinkStyle}
                      fullWidth
                      className={s.custom_input}
                      defaultValue={port.site || ''}
                      onChange={this.handleChangeIconColor}
                    />
                    <div className={s.edit_container_site_actions}>
                      <button type="submit">
                        <CheckIcon style={this.state.iconGreen ? {fill:"#75C422"} : null}/>
                      </button>
                      <button onClick={() => this.setState({isEditSite: false})} type="button"><BlockIcon/></button>
                    </div>
                  </Form>
                :
                <div className={cx(s.edit_container, s.flex)}>
                  <div className={s.edit_container_site}>
                    <p>Site:
                      {renderSite()}
                    </p>
                  </div>
                  <div className={s.edit_container_site_actions}>
                    { port.site !== rPort.site ? <button onClick={this.handleRestoreOriginSite} type="button"><RestoreIcon /></button> : null }
                    <button onClick={this.handleEditSite} type="button"><EditIcon/></button>
                    {port?.site && <button onClick={this.handleResetSite} type="button"><CloseIcon /></button>}
                  </div>
                </div>
                }
                <div className={s.edit_container}>
                  <h2 className={s.edit_container_header}>PORT Restrictions</h2>
                    <Form ref="restrictionsForm"
                      onValidSubmit={this.handleSave}
                      name="restrictionsForm"
                      onChange={() => this.handleAddConfirmModal()}
                    >
                      <div className={s.restrictions}>
                        {
                          restrictions.map((item, i) => (
                            <div key={i} style={{ maxWidth: item.width}}>
                              <FormsyText
                                autoComplete="off"
                                name={item.name}
                                floatingLabelText={item.label}
                                floatingLabelStyle={labelStyle}
                                floatingLabelShrinkStyle={labelShrinkStyle}
                                fullWidth
                                defaultValue={port.portLimitations[item.name] && port.portLimitations[item.name] !== 0 ? port.portLimitations[item.name] : ''}
                                previousValue={originPort.portLimitations[item.name] && originPort.portLimitations[item.name] !== 0 ? originPort.portLimitations[item.name] : ''}
                                validations={item.validations}
                                validationError={item.validationError}
                              />
                            </div>
                          ))
                        }
                      </div>
                    </Form>
                </div>
                <div className={s.edit_container}>
                  <div><h2 className={s.edit_container_header}>Available Lift and Cranes</h2>
                  <div className={s.available_lift_cranes} style={{marginTop: '-10px'}}>
                    {
                      CRANE_GROUPS.map((key, i) => {
                        const group = port.liftsCranes[key];
                        const originGroup = originPort.liftsCranes?.[key];
                        return (<AvailableLiftCranes
                          key={key}
                          ref={key}
                          mtRange={key}
                          group={group}
                          originGroup={originGroup}
                          floatingLabelStyle={floatingLabelStyle}
                          floatingLabelFocusStyle={floatingLabelFocusStyle}
                          floatingLabelShrinkStyle={floatingLabelShrinkStyle}
                          handleAddConfirmModal={this.handleAddConfirmModal}
                          inputStyle={inputStyle}
                        />);
                      })
                    }
                  </div>
                </div>
                <div className={s.edit_container} style={{marginBottom: '0'}}>
                  <div className={s.edit_container_with_actions}>
                    <h2 className={s.edit_container_header} style={{margin: '0'}}>General Description</h2>
                    <div className={s.edit_description_actions}>
                      {!this.state.isEditDescription && rPort.additionalDescription !== port.additionalDescription ?
                        <button onClick={this.handleRestoreOriginDescription} type="button"><RestoreIcon /></button> : null
                      }
                      {!this.state.isEditDescription ?
                        <button onClick={this.handleEditDescription} type="button"><EditIcon/></button> : null
                      }
                    </div>
                  </div>
                  { this.state.isEditDescription ?
                    <Form ref="descriptionForm"
                      onValidSubmit={this.handleEditDescription.bind(this, null)}
                      style={{marginTop: '12px'}}
                      onChange={() => this.handleAddConfirmModal()}
                    >
                      <div className={s.edit_description}>
                        <FormsyText
                          className={s.edit_description_textarea}
                          validations="minLength:2,maxLength:5000"
                          validationError="2 - 5000 characters"
                          name="additionalDescription"
                          fullWidth
                          placeholder="Type your text..."
                          multiLine
                          rows={7}
                          defaultValue={this.state.port.additionalDescription}
                          style={{ borderRadius: "8px" }}
                        />

                      </div>
                      <ButtonForm onClick={this.handleEditDescription} />
                    </Form>
                    :
                    <div className={s.edit_description_text}>
                      <p>
                        <Diff preLine oldValue={originPort.additionalDescription || ''} newValue={port.additionalDescription || ''} colorScheme="green_preLine" />
                      </p>
                      <span title="Edit">
                      </span>
                    </div>
                  }
                </div>

                <ServiceProviders ref="serviceProvidersForm"
                  providers={port.serviceProviders}
                  originProviders={originPort.serviceProviders}
                  handleAddConfirmModal={this.handleAddConfirmModal}
                  country={port.country}
                  toggleSpEdit={this.toggleSpEdit}
                  />

                <Form noValidate ref="facilitiesForm" name="facilitiesForm">
                    <div className={s.edit_available_facilities}>
                      <h2 className={s.edit_container_header}>Available facilities</h2>
                      <div className={s.edit_facilities_check}>
                        {availableFacilities.map((fl, i) => (<div key={i}>
                          {
                              fl.map(item => (
                                <CustomCheckbox as={CheckBox} defaultValue={port.facilities[item.name] || false} previousValue={originPort.facilities[item.name] || false} key={item.name} className={s.checkbox} name={item.name} label={item.label} onChange={() => this.handleAddConfirmModal()} />
                              ))
                            }
                        </div>)
                        )
                        }
                      </div>
                    </div>
                  </Form>

                  <Form noValidate ref="warehousesForm" name="warehousesForm">
                    <div className={s.edit_warehouses}>
                      <h2 className={s.edit_container_header}>Available Warehouses</h2>
                      <div className={s.edit_wrapper_warehouses}>
                        {WAREHOUSES.map((wl, i) => (<div className={s.wrapper_grid} key={i}>
                          {
                            wl.map(item => (
                              <CustomCheckbox as={CheckBox} defaultValue={port.warehouses[item.name] || false} previousValue={originPort.warehouses[item.name] || false} key={item.name} className={s.checkbox} name={item.name} label={item.label} onChange={() => this.handleAddConfirmModal()} />
                            ))
                          }
                        </div>)
                        )
                        }
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
           </div>
          : null}
        </div>
    );
  }
}

export default connect(state => state.port, portActions)(
  withStyles(s)(withStyles(sg)(EditPort)),
);
