import React from "react";
import Layout from "../../components/Layout";
import {
  clearSelectedContract,
  getFixedContracts,
  getVoyageContractById,
  getVoyageContractInvoices
} from "../../actions/contracts";

import { getBl } from "../../actions/bl";
import { getInvoice } from "../../actions/invoices";
import { getAccountFilters } from "../../actions/login";
import { invoicesAllowedCompanyIds, isTestEnv } from "../../config";

const availableContractTypes = ["voyage", "tc", "bill-of-lading", "invoice"];
const addNewReservedWords = ['-', 'create'];

export default {
  path: "/contracts/:contractId?/:action?",
  async action({ params, store, token, next, query, previousPath, pathname }) {
    let err;
    const Contracts = (
      await import(
        /* webpackChunkName: 'Contracts' */ "../../components/Contracts/Contracts"
      )
    ).default;
    const contractType = query.contractType || 'voyage';
    if (!availableContractTypes.includes(contractType)) {
      return await next();
    }

    if (contractType === "bill-of-lading") {
      if (typeof window !== "undefined" && params.contractId && !addNewReservedWords.includes(String(params.contractId).toLowerCase())) {
        const res = await store.dispatch(getBl(params.contractId, token));
        if (res.status !== 200) {
          return await next();
        }
      }
    }

    if (contractType === "invoice") {
      const companyId = store.getState().login?.user?.company?._id;
        const shouldShowInvoice = isTestEnv || invoicesAllowedCompanyIds.includes(companyId)
        if(!shouldShowInvoice) return await next();
        if (typeof window !== 'undefined' && params.contractId && params.contractId!=='-') {
          try {
            const res = await store.dispatch(getInvoice(params.contractId, token));
            if(res.status && res.status!==200 && res.status!==304) return await next();
          } catch (error) {
            console.log(error);
            return await next();
            err = e;
          }
        }
        try {
          if (!previousPath || pathname.slice(12) !== previousPath.slice(12)) {
            await store.dispatch(getAccountFilters(token));
          }
        } catch (e) {
          console.error(e);
          err = e;
        }
      }
      
      if(contractType==='tc' || contractType==='voyage') {
        if (typeof window !== 'undefined') {
            if (!params.contractId) {
                store.dispatch(clearSelectedContract());
            }
            try {
              if (params.contractId && !addNewReservedWords.includes(String(params.contractId).toLowerCase())) {
                await store.dispatch(getVoyageContractById(params.contractId, contractType));
                await store.dispatch(getVoyageContractInvoices(params.contractId))
              }
            } catch (e) {
              err = e;
            }
          }
          try {
            if (!previousPath || pathname.slice(12) !== previousPath.slice(12)) {
              await store.dispatch(getAccountFilters(token));
            }
          } catch (e) {
            err = e;
            console.error(e);
          }
      }

      return {
        title: 'SHIPNEXT - Shipping Marketplace and Transportation Platform',
        component: <Layout><Contracts type={contractType} err={err} {...params} /></Layout>,
      };
  }
};
