import history from '../../core/history';

export default function (state) {
  if (state.invite) {
    const invite = state.invite;
    state.invite = undefined;
    switch (invite.type) {
      case 'BillOfLading':
        return setTimeout(() => history.push(`/contracts/${invite.data.billOfLadingId}?contractType=bill-of-lading`), 0);
        break;
      case 'Invoice':
        return setTimeout(() => history.push(`/contracts/${invite?.data?.invoiceId}?contractType=invoice`), 0);
        break;
    }
  }
}
