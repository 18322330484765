import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import s from './Home.scss';
import Link from "../Link";
import Play_video from "../Icons/Play_video";
import RoundThemableButton from '../Common/RoundThemableButton';
import Main_Deck from '../Icons/Main_Deck';

class ManagmentSolutions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playVideo: false
    };
    this.visibilityState = {};
  }
  togglePlayVideo = () => {
    this.setState({ playVideo: !this.state.playVideo });
  };

  render() {
    return (
      <div className={s.managment_solutions}>
        <div className={s.container}>
        <RoundThemableButton className={s.round_btn} component={Link} to="/main-deck">
          <span className={s.arrow}>
            <Main_Deck opacity='1'/>
          </span>
          <span className={s.text}>get started</span>
        </RoundThemableButton>
          <h2>Supply Chain <br /> Management Solution</h2>
          <div>
            <div className={s.left_part}>
              <div>
                <p>SHIPNEXT is a blockchain-driven Digital Shipping Marketplace and a reverse trading Platform. It uses Natural language processing, machine learning, Linear programing, AI and Big Data analysis for instant and to generate the best Shipping and Transport solutions.</p>
                <p>SHIPNEXT is patented solution, that uses unique algorithms, as well as data and real-time information from over 85 data-bases for instant Cargo-to-Ship matching and integration in door-to-door delivery.</p>
              </div>
              <Link className={s.our_technology} to={'/technology'}>Our technology</Link>
            </div>
            <div className={s.right_part}>
              <div>
                <img src="/images/poster_video.png" alt="poster_video"  style={{display: !this.state.playVideo ? 'block' : 'none'}} />
                <span id="overlay" className={s.btn_play} onClick={this.togglePlayVideo} style={{display: !this.state.playVideo ? 'block' : 'none'}}>
                  <Play_video />
                </span>
                {this.state.playVideo &&
                  <video
                    ref="video"
                    style={{ width: '100%', maxWidth: '720px' }}
                    controls
                    controlsList="nodownload"
                    autoPlay
                  >
                    <source
                      src="https://shipnext.com/Logo_final.mp4"
                      type="video/mp4"
                    />
                    Your browser doesn't support HTML5 video tag.
                  </video>
                }

              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default (withStyles(s)(ManagmentSolutions));
