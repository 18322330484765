import React from 'react';
import pure from 'recompose/pure';

const _OpenInNewTabIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
    <path d="M3.75 15.75C3.3375 15.75 2.98438 15.6031 2.69063 15.3094C2.39687 15.0156 2.25 14.6625 2.25 14.25V3.75C2.25 3.3375 2.39687 2.98438 2.69063 2.69063C2.98438 2.39687 3.3375 2.25 3.75 2.25H8.25C8.4625 2.25 8.64063 2.32188 8.78438 2.46563C8.92813 2.60938 9 2.7875 9 3C9 3.2125 8.92813 3.39062 8.78438 3.53438C8.64063 3.67813 8.4625 3.75 8.25 3.75H3.75V14.25H14.25V9.75C14.25 9.5375 14.3219 9.35938 14.4656 9.21563C14.6094 9.07188 14.7875 9 15 9C15.2125 9 15.3906 9.07188 15.5344 9.21563C15.6781 9.35938 15.75 9.5375 15.75 9.75V14.25C15.75 14.6625 15.6031 15.0156 15.3094 15.3094C15.0156 15.6031 14.6625 15.75 14.25 15.75H3.75ZM14.25 4.8L7.8 11.25C7.6625 11.3875 7.4875 11.4563 7.275 11.4563C7.0625 11.4563 6.8875 11.3875 6.75 11.25C6.6125 11.1125 6.54375 10.9375 6.54375 10.725C6.54375 10.5125 6.6125 10.3375 6.75 10.2L13.2 3.75H11.25C11.0375 3.75 10.8594 3.67813 10.7156 3.53438C10.5719 3.39062 10.5 3.2125 10.5 3C10.5 2.7875 10.5719 2.60938 10.7156 2.46563C10.8594 2.32188 11.0375 2.25 11.25 2.25H15C15.2125 2.25 15.3906 2.32188 15.5344 2.46563C15.6781 2.60938 15.75 2.7875 15.75 3V6.75C15.75 6.9625 15.6781 7.14062 15.5344 7.28438C15.3906 7.42813 15.2125 7.5 15 7.5C14.7875 7.5 14.6094 7.42813 14.4656 7.28438C14.3219 7.14062 14.25 6.9625 14.25 6.75V4.8Z" fill="#4380C7"/>
  </svg>
);

const OpenInNewTabIcon = pure(_OpenInNewTabIcon);
OpenInNewTabIcon.displayName = 'OpenInNewTabIcon';
OpenInNewTabIcon.muiName = 'SvgIcon';

export default OpenInNewTabIcon;
