import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';
import Dialog from '../Common/DialogGreyTitle';
import s from './ConfirmEmailDialog.scss';
import { requestConfirmEmail, subscribe, toggleSubscribeDialog, logout } from '../../actions/login';
import { isAllowedPathForUnsubscribed, isNotAllowedForNotVerified } from '../../core/utils';
import cx from 'classnames';
import Link from '../Link';


class WaitingForApprovalDialog extends PureComponent {
  static propTypes = {
    requestConfirmEmail: PropTypes.func,
    path: PropTypes.string,
    isPublicPath: PropTypes.bool,
    isUnLockedPathForUnapproved: PropTypes.bool,
  };
  static defaultProps = {
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSwitchAccount = () => {
    this.props.logout();
  };

  render() {
    if(!this.props.user) {
      return null;
    }
    if(this.props.user.approved || !this.props.user.emailVerified) {
       return null;
    }
    const show = (!this.props.isPublicPath && !this.props.isUnLockedPathForUnapproved) || this.props.path === '/main-deck';
    return (
      <div >
        <Dialog
          open={show}
          contentClassName={cx(s.dialogContent)}
          bodyClassName={cx(s.dialogBody)}
          title={'Waiting for approval'}
        >
          <div>
            <div className={s.wrapper_access}>
              To continue using SHIPNEXT your profile must be confirmed by the owner of your company.
              <br/>
              Please contact company owner for confirmation.
              <br/>
              If you have any questions, send it to <a href="mailto:support@shipnext.com">support@shipnext.com</a>.
              <br/>
              <a onClick={this.handleSwitchAccount} >Switch account</a>
            </div>
            <div className={s.info}>
              <a href="/legal" target="_blank">
                Terms & conditions
              </a>
              <a href="/rules-regulations" target="_blank">
                Rules & Regulations
              </a>
              <Link to="/">
                Back to home
              </Link>
            </div>
          </div>
        </Dialog>

      </div>
    );
  }
}


export default connect(
  state => ({ ...state.login }),
  {requestConfirmEmail, logout },
)(withStyles(s)(WaitingForApprovalDialog));
