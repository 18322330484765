import React from 'react'

import TabsPicker from '../Common/TabsPicker/TabsPicker'
import PortListSection from '../Port/PortListSection'
import AllFleetSection from '../Vessel/AllFleet/allFleetSection'

export default function FleetOrPortsSection(props) {
    const tabs = [
        {
            tabName: 'Fleet',
            value: 'fleet',
            component: <AllFleetSection {...props} action={props.entityAction} vesselId={props.entityId} create={props.entityAction === 'create'} pickThemeFrom="vesselList"/>
        },
        {
            tabName: 'Ports',
            value:'ports',
            component: <PortListSection {...props} addPortEnabled={false} action={props.entityAction} portId={props.entityId}/>
        }
    ]
  return (
    <TabsPicker queryStringName='rightPaneTab' tabs={tabs} />
  )
}
