import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {connect} from 'react-redux';
import s from '../Documents/Cargo.scss';
import {sendRequest} from '../../actions/cargo';
import {staticPopup} from '../../actions/runtime';
import Preview from './Preview/Preview';
import history from '../../core/history';
import mergeTerms from './mergeTerms';
import Request from '../Documents/Request';
import CargoHeader from './CargoHeader';
import PropTypes from 'prop-types';
import PubSub from 'pubsub-js';
import {addUniqueIds} from "../Common/Tags/utils";
import {createEditAdaptert, createPreviewAdapter, createServerAdapter} from "../Documents/adapters";
import { getSubstringBetween } from './utils';

const titles = {
  edit: 'MY CARGO: UPDATE REQUEST',
  create: 'MY CARGO: NEW REQUEST',
};
class Cargo extends Component {

  static defaultProps = {
    showHeader: true,
    simpleFormEnabled: true,
    serverAdapter: createServerAdapter(),
    editAdapter: createEditAdaptert(),
    previewAdapter: createPreviewAdapter(),
    requestProps: { cargoListProps: { freightSectionEnabled: false }, editButtonLabel: "Preview", preambleEnabled: false },
  }

  static propTypes = {
    type: PropTypes.string,
    action: PropTypes.string,
    sendRequest: PropTypes.func,
    requestProps: PropTypes.object,
  };

  static contextTypes = {
    cargoTypes: PropTypes.array,
    voyageTerms: PropTypes.array,
    showMessage: PropTypes.func,
    blockTransition: PropTypes.func,
  };

  state = {
    titles: {
      portsTitle: 'PORTS & DATES',
    },
    termsCounter: 0,
    tags: [],
    previewErrors: null,
    savedPreviewData: {},
  };
  fields = {};
  // Request component ref to manipulate form steps
  _requestElement = null;

  constructor(props, context) {
    super(props);
    this._form = props.cargo;

    this.state.title = titles[props.actionType];

    if (this._form) {
      //TODO if edit send to another route and add _id
      this._form.cargo.forEach(c => (c._id = undefined));
      this._form.contract = this._form.contract._id;
      this._form.terms = mergeTerms(this._form.terms, this._form.terms);
      this.state.tags = addUniqueIds(this._form.tags);
    }

    if (props.user && props.user.company && props.user.company.settings && props.user.company.settings.voyage) {
      this.state.voyageSettings = props.user.company.settings.voyage;
      this.state.voyageSettings.defaultContract = this.state.voyageSettings.contracts.find(c => c.default);
    }

    if (this.props.cargo) {
      this.state.cargoRequest = this.props.editAdapter.adapt({ cargoRequest: this.props.cargo }, context.cargoTypes).cargoRequest;
      console.debug(this.state.cargoRequest);
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (
      nextProps.errors &&
      this.props.errors !== nextProps.errors &&
      nextProps.errors.length
    ) {
      this.handleClosePreview();

      return;
    }

    if (nextProps.error && this.props.error !== nextProps.error) {
      this.handleClosePreview();
    }

    if (this.props.hash === '#preview' && nextProps.hash !== '#preview') {
      this.setState({ preview: undefined });
    }
  }

  delayVesselListUpdate = () => {
    this._isDelayed = true;
  }

  // must return unblock function
  blockHistory = () => this.unblock = this.context.blockTransition('Are you sure you want to leave this page?', (location) => {
    if ( this._requestElement !== null) {
      const initialState = JSON.stringify({ ports: this._requestElement.initialState.ports, simpleForm: this._requestElement.initialState.simpleForm });
      const curentState = JSON.stringify({ ports: this._requestElement.state.ports, simpleForm: this._requestElement.state.simpleForm });
      if (initialState === curentState) {
        return false;
      }
    }
    if (location.pathname.indexOf('/add-cargo/') !== -1
      || location.pathname.match(/main-deck\/general\/.+\/create/)
      || location.pathname.match(/main-deck\/general\/[a-z0-9]{24}/)
      || location.pathname.match(/my\/cargo\/add/)
      || location.pathname.match(/cargo\/general\/create/)){
      return false;
    }
    return true;
  });

  componentDidMount() {
/*    if (this._form && this._form.cargoType) {
      setTimeout(this.genericChange, 500);
    }*/
    PubSub.subscribe("monitor::updateList", this.delayVesselListUpdate);
    if (typeof window !== 'undefined') {
      this.blockHistory();
    }
  }

  componentWillUnmount() {
    PubSub.unsubscribe(this.delayVesselListUpdate);
    if (typeof window !== 'undefined') {
      this.unblock && this.unblock();
    }
    if (this._isDelayed === true) {
      this._isDelayed = false;
      PubSub.publish("monitor::updateList");
    }
  }

  handleSend = () => {
    this.fields.requestType.setValue('request');
  };

  handleSaveToTemplates = () => {
    this.fields.requestType.setValue('template');
  };

  handleSaveToDrafts = () => {
    this.fields.requestType.setValue('draft');
  };

  resetCargoId = cargo => {
    // if simple form recognition returned cargo without _id field, mock _id to fill cargo autocomplete but don't send cargoId to backend
    if (!cargo?.cargoName?.cargoWithoutId) {
      cargo.cargoId = cargo.cargoName && cargo.cargoName._id;
    }
    else {
      Reflect.deleteProperty(cargo.cargoName, "_id");
      Reflect.deleteProperty(cargo.cargoName, "cargoWithoutId");
    }
    return cargo;
  }

  keepSimpleFormLetterIfUsedRecognition = request => {
    if (!request.isUsedRecognition || !String(request.simpleForm).trim().length) {
      Reflect.deleteProperty(request, "simpleForm");
      Reflect.deleteProperty(request, "isUsedRecognition");
      Reflect.deleteProperty(request, "mailId");
    }
  }

  handlePreview = (request, previewErrors) => {

    //request.cargo = request.cargo.filter(cargo => !!cargo.cargoName);
    console.debug(JSON.parse(JSON.stringify(request)));
    request = this.props.previewAdapter.adapt(request);
    console.debug(request);
    let tags = [];

    if (request.cargo.length) {
      tags = request.cargo.map(c => c?.cargoName?.tags ? c.cargoName.tags : []).flat().map(t => ({...t, system: true, canEdit: false}));
    }
    this.setState(state => ({
      tags: [...state.tags, ...tags].filter((tag, index, arr) => arr.findIndex(t => (t._id === tag._id) || (t.value === tag.value)) === index)
    }))
    if (!request.terms) {
      request.terms = {};
    }

    if (!request.terms.default) {
      request.terms.default = [];
    }

    if (!request.terms.user) {
      request.terms.user = [];
    }

    request.terms.default = request.terms.default.filter(t => t);

    request.cargoType = request.cargo?.[0]?.cargoType;
    if (this.props.cargo) {
      request.realUserEmail = this.props.cargo.realUser?.email;
    }
    if (this.props.mailFrom) {
      request.realUserEmail = this.props.mailFrom.includes("<") ? getSubstringBetween(this.props.mailFrom, "<", ">") : this.props.mailFrom;
    }
    Object.assign(request, this.state.savedPreviewData);

    if (request.realUserEmail) {
      request.requestKind = 'broker';
    } else {
      request.requestKind = 'public';
    }

    this.setState({
      preview: request,
      previewErrors,
    });

    history.push('#preview');
  };

  handleTags = tags => {
    this.setState(state => ({
      ...state,
      tags
    }))
  }

  handleSubmit = async (previewFormModel) => {
    let request = { ...this.state.preview };
    request = this.props.serverAdapter.adapt(request);
    if (this.state.tags) {
      // TODO: implement private and public
      request.tags = {
        private: this.state.tags.filter(tag => !tag.system),
      };
    }

    const { vesselRequirements } = request
    if (vesselRequirements.registerClasses) {
      vesselRequirements.registerClasses = vesselRequirements.registerClasses.map(x => ({
        iacsRegister: x.iacsRegister,
        excluded: x.excluded,
        registerClass: x._id,
      }))
    }

    if (vesselRequirements.flags) {
      vesselRequirements.flags = vesselRequirements.flags.map(x => ({
        flag: x.flag._id,
        excluded: x.excluded,
      }))
    }

    if (previewFormModel && typeof previewFormModel === "object" && previewFormModel !== null) {
      request = {
        ...request,
        ...previewFormModel,
      };
    }
    this.keepSimpleFormLetterIfUsedRecognition(request);
    if (this.props.cargo) {
      request._id = this.props.cargo._id;
    }
    if (this.props.mailId) {
      request.mailId = this.props.mailId;
      request.linkEmail = true;
    }
    this.unblock && this.unblock();
    const res = await this.props.sendRequest(request, this.props.user, this.props.withoutRedirect);
    if (res.status === 200) {
      this.props?.handleClose(null, res);
    }
  };

  closePreview = () => {

    this.setState({
      preview: undefined,
    });
  }

  handleClosePreview = (previewData) => {
    if (!previewData) {
      return;
    }
    if (previewData.requestKind === 'public') {
      previewData.realUserEmail = "";
    }
   this.state.savedPreviewData = previewData;
   this.closePreview();

    if (this._requestElement) {
      this._requestElement.openStepWithInvalidInput();
    }
  };

  handleBackToSimpleForm = () => {
    this.closePreview();

    if (this._requestElement) {
      this._requestElement.openSimpleFormStep();
    }
  }

  handleClose = () => {
    if (this.props.user) {
      history.push('/my/cargo');
    } else {
      history.push('/');
    }
  };


  saveRequestRef = el => this._requestElement = el;

  render() {
    const { errors, cargo, loading, showHeader = true, myCargoDisabled = false } = this.props;
    const { cargoRequest } = this.state;
    const basicTitle = this.props.cargo ? titles.edit : titles.create;
    const title = `${basicTitle}${this.state.preview ? " PREVIEW" : ""}`;

    const headerProps = {
      title,
      handleClose: this.state.preview ? this.handleClosePreview : this.props.handleClose,
    };
    return (
      <div className={s.root} style={{ display: "flex", flexDirection: "column" }}>
        {
          showHeader
            ? (
              <CargoHeader {...headerProps} />
            )
            : null
        }
        <div className={s.container}>
          <div>
            <Request
              innerRef={this.saveRequestRef}
              styles={s}
              errors={errors}
              cargo={cargoRequest}
              request={cargoRequest}
              loading={loading}
              handlePreview={this.handlePreview}
              handleClosePreview={this.handleClosePreview}
              user={this.props.user}
              simpleFormEnabled={this.props.simpleFormEnabled}
              isEditing={!!this.props.cargo}
              {...this.props.requestProps}
            />
          </div>
        </div>
        {this.state.preview
          ?
            <div className={s.preview_container} style={{ position: "absolute", top: this.props.showHeader ? "48px" : "0px", left: "0px", right: "0px", zIndex: 1111, overflow: "auto", background: "#fff", bottom: "0px" }}>
              <Preview
                loading={this.props.loading}
                request={this.state.preview}
                onSend={this.handleSubmit}
                handleTags={this.handleTags}
                tags={this.state.tags}
                onClose={this.handleClosePreview}
                handleBackToSimpleForm={this.handleBackToSimpleForm}
                cancelLabel={'BACK TO EDIT'}
                sendLabel={this.props.cargo ? 'UPDATE' : 'CREATE'}
                errors={this.state.previewErrors}
                user={this.props.user}
                requestTypeSelectorEnabled={!this.props.cargo}
                myCargoDisabled={myCargoDisabled}
              />
            </div>
          : null}
        {this.state.confirmTransitionDialog
          ? this.state.confirmTransitionDialog
          : null}

      </div>
    );
  }
}

export default connect(state => ({ ...state.cargo, user: state.login.user }), {
  sendRequest,
  staticPopup,
})(withStyles(s)(Cargo));
