import {EmailHeadersCollapsed} from "../EmailHeaders";
import EmailActionButtons from "../EmailActionButtons";
import CustomTooltip from "../../Common/CustomTooltip";
import {dateTime, dateTimeShiftedToLocal} from "../../../core/format";
import EmailDetailsDialog from "../EmailDetailsDialog";
import MessageContent from "../MessageContent";
import React from "react";
import styled from "styled-components";
import cx from "classnames";
import Link from "../../Link";
import {getAttachmentAProps} from "../../../core/utils";

function defaultDateRenderer(message) {
  return (<CustomTooltip
    horizontalAlign={"left"}
    tooltip={<div>
      <div>Shipnext Received: {dateTimeShiftedToLocal(message.createdAt)}</div>
      <div>Received: {dateTimeShiftedToLocal(message.receivedAt)}</div>
    </div>}
  >
    <div>{dateTimeShiftedToLocal(message.receivedAt)}</div>
  </CustomTooltip>);
}

function defaultActionButtonsRenderer(props){
  return (<EmailActionButtons
    onReplyClick={props.onReplyClick}
    onForwardClick={props.onForwardClick}
    onUnreadClick={props.onUnreadClick}
    canReply={props.message.replyTemplate?.replyGates?.length}
  />)
}

export default styled(({ message, onReplyClick, onForwardClick, onUnreadClick, actionButtons, className, searchRegExp, dateRenderer = defaultDateRenderer, search, actionButtonsRenderer = defaultActionButtonsRenderer, afterIframeRender }:
{ message: any, searchRegExp: RegExp | null, onReplyClick: () => void, onForwardClick: () => void, onUnreadClick: ((e, threadId) => Promise<void>) | any, search: string, className: string, actionButtons: any, dateRenderer: () => any, actionButtonsRenderer: () => any, afterIframeRender: () => any }) => {
  return (<div className={cx(className, "original_message")}>
    <div className="original_message_head">
      <div>
        <EmailHeadersCollapsed
          message={message}
          searchRegExp={searchRegExp}
        />
      </div>
      <div>
        {actionButtonsRenderer({ message, onReplyClick, onUnreadClick, actionButtons, onForwardClick })}
        <div>{dateRenderer(message)}</div>
        <div><EmailDetailsDialog message={message} />{message.refNo ? <small>{message.exchangeId ? <Link to={`/emails/${message.exchangeId}/-/---/-/-`}>REF:{message.refNo}</Link> : `REF:${message.refNo}` }</small> : null}</div>
      </div>
    </div>
    <div className="original_message_text">
                  <pre>
                    <MessageContent
                      refNo={message.refNo}
                      search={search}
                      afterRender={afterIframeRender}
                    >
                      {message.html}
                    </MessageContent>
                    {message.originalAttachments.length > 0 ? (
                      <div className={"attachments"}>
                        {message.originalAttachments.map(file => {
                          return (
                          <div>
                            <span className="material-icons-outlined">
                              attach_file
                            </span>
                            <a
                              {...getAttachmentAProps(file)}
                            >{file.name}</a>
                          </div>);
                        })}
                      </div>
                    ) : null}
                  </pre>
    </div>

  </div>);
})`
  .original_message_head {
    line-height: 24px;
    padding: 10px 0;
    border-bottom: 1px solid var(--stroke-light-gray2);
    font-size: 12px;
    font-weight: normal;
    text-transform: none;
    text-align: left;
    display: flex;
    justify-content: space-between;

    > div:first-child {
      flex: 1
    }

    > div:last-child {
      /* display: flex;
       justify-content: flex-end;
       flex-direction: column;*/

      > div {
        min-width: 125px;
        color: var(--text-medium);
        text-align: right;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
    small {font-size: 10px}
  }

  .original_message_text {
    padding-top: 12px;
    white-space: pre-line;

    .attachments {
      margin-top: 8px;
      border-top: 1px dashed var(--stroke-light-gray1);
      padding-top: 8px;

      > div {
        display: flex;
        align-items: center;
        > span:first-child {
          font-size: 20px;
          color: var(--text-medium);
          transform: rotate(45deg);
        }

        a {
          margin-left: 4px;
          word-break: break-all;
        }
      }

      > div + div {
        margin-top: 8px;
      }
    }
  }
`
