import React from 'react';
import {
  BaseContactRowWrapper,
  ContactButtons,
  ContactsHeader,
  MultiValueTd,
  ReduxlessContactsTable
} from "./ContactsTable";
import {connect} from "react-redux";
import {
  checkAll,
  checkContact,
  createCompany,
  expandGroup,
  getCompanyDetails,
  getCompanyList
} from "../../actions/contacts";
import { getAccountFilters } from "../../actions/login";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "../Monitor/TableNew.scss";
import {VesselSelector} from "../Monitor/VesselSelector";
import Col from "../Monitor/RowBlocks/Col";
import {Text} from '../Contracts/shared/styled';
import styled from "styled-components";
import CustomCheckbox from "../Common/CustomCheckbox";
import {Avatar} from "./Avatar";
import cx from "classnames";
import IconButton from "material-ui/IconButton";
import IconMenu from "material-ui/IconMenu";
import MoreVertIcon from "material-ui/svg-icons/navigation/more-vert";
import MenuItem from "material-ui/MenuItem";
import NarrowMenuItem from "../NewInputs/NarrowMenuItem";
import {PlusButtonBlue} from "../Common/AddButton";
import history from "../../core/history";
import ArrowDropDown from "material-ui/svg-icons/navigation/arrow-drop-down";
import TagsView from "../Common/Tags/TagsView";
import {formatIndustry, formatSector} from "./contactBookUtils";


const StyledRowWrapper = styled(BaseContactRowWrapper)`

  > div:nth-child(2) {
    flex: 1;
  }
  > div:nth-child(3){
    flex: 1;
  }
  > div:nth-child(4) {
    width: 108px;
    min-width: 108px;
  }
  > div:nth-child(5) {
    width: 60px;
    min-width: 60px;
  }
  &.group {
    background: var(--bg-new-offer, #F1F9FF);
  }
  .company-dropdown-icon {
    margin-left: -4px;
  }
  &.company-expanded {
    .company-dropdown-icon {
      transform: rotate(180deg);
    }
  }
  &.child-company {
    padding-left: 18px;
    > div:first-child{
      border-right: 4px solid var(--text-links-light, #82C3FF);
    }
  }
  a {
    text-decoration: underline;
  }
  &.readonly {
    >div:nth-child(1) {
      display: none;
    }
  }
  .custom-checkbox.custom-checkbox {
    margin-top: 5px !important;
  }
`;

const CompaniesTableHeader = styled(({ className, checkAll, rows, withCheckbox = false, background = 'var(--bg-light-grey)', readonly, ...props }) => {
  return (
    <div className={className}>
      <StyledRowWrapper noHover h="48px" header className={cx(readonly && 'readonly')}>
        <Col>
          {withCheckbox ? (<VesselSelector style={{ maxWidth: '26px', marginTop: '-4px', overflow: 'unset' }} vertical refList={[]} handleCheck={checkAll} vessels={rows} />) : null}
        </Col>
        <Col>
          <Text>
            COMPANY
          </Text>
          <Text>
            CITY / COUNTRY
          </Text>
        </Col>
        <Col>
          <Text>
            EMAIL
          </Text>
          <Text>
           PHONE NUMBER
          </Text>
        </Col>
        <Col>
          <Text>
            TAGS
          </Text>
        </Col>
        <Col>
          <Text>
            CONTACT
          </Text>
        </Col>
      </StyledRowWrapper>
    </div>);
})`
  background:${(props) => props.background};
`;

 const CompanyName = styled(({ company, ...props}) => {
   const place = [company.city?.name, company.country?.name].filter(v => v).join(' / ');
  return (<div {...props}>
    <Avatar contact={company} />
    <div>
      <Text isBold emptyValue={""} >{`${company.name}`}</Text>
      <Text emptyValue={""}>{place}</Text>
    </div>
  </div>);
})`
 display: flex;
 > div:first-child {
   margin-right: 8px;
 }
  >div:nth-child(2) {
    overflow: hidden;
    >div {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

function CompanyRow({ row, onSelect, className, nextRowId, prevRowId, withCheckbox = false, onExpand, isChild, readonly , ...props }) {
  const isGroup = row.type === 'group';
  const tags = row.tags || []
  return (
    <StyledRowWrapper onClick={onSelect} className={cx(className, row.type, row.expanded && 'company-expanded', isChild && 'child-company', readonly && 'readonly')} id={row.id} data-nextid={nextRowId} data-previd={prevRowId}>
      <Col>
        {withCheckbox ? <CustomCheckbox style={{ marginTop: '-4px', marginBottom: '2px' }} checked={row.selected} onClick={ev => ev.stopPropagation()} onCheck={props.checkRow} className={s.ref_checkbox} /> : null}
        {isGroup && row.companies?.length ? (<ArrowDropDown className={"company-dropdown-icon"} onClick={onExpand} />) : null}
      </Col>
      <Col>
        <CompanyName company={row} />
      </Col>
      <Col>
        <Text emptyValue={""}>{row.emails?.[0]}</Text>
        <Text emptyValue={""}><MultiValueTd values={row.phones} renderValue={(c) => <div>{c.number}</div>} /></Text>
      </Col>
      <Col>
        <TagsView tags={tags.map(t => t.label)} showMore={2} />
      </Col>
      <Col>
        <ContactButtons contact={row} />
      </Col>
    </StyledRowWrapper>
  );
}

function GroupRow({ row, onSelect, className, selectedContactId, ...props }) {
  return (<div>
    <CompanyRow row={row} onSelect={onSelect.bind(this, row.id, row)} className={cx(className, selectedContactId === row.id && 'selected')} {...props} />
    {row.expanded ? row.companies.map(c => <CompanyRow isChild={true} row={c} onSelect={onSelect.bind(this, c.id, c)} className={cx(className, selectedContactId === c.id && 'selected')} {...props} />) : null}
  </div>);
}


export class CompaniesTable extends ReduxlessContactsTable {
  useVirtualList = false;
  sortProps = { withFavorites: false };
  renderTableHeader(): * {
    return <CompaniesTableHeader rows={this.props.contactList.data} checkAll={this.props.checkAll} {...this.tableHeaderProps} readonly={this.props.readonly} withCheckbox={this.props.withCheckbox} />;
  }

  handleOpenCreateGroup = () => {
    history.push('/$1/$2/add-group?contactsTab=companies');
  }
  handleOpenCreateCompany = () => {
    history.push('/$1/$2/add-company?contactsTab=companies');
  }
  handleContactSelect = (id, company) => {
    history.push(`/$1/${company.type || 'company'}:${id}/-?contactsTab=companies`);
  }

  handleExpandGroup = (id) => {
    this.props.expandGroup(id);
  }

  handleToggleFavorites = null;

  renderHeaderButtons = () => {
    return (<IconMenu
      iconButtonElement={<IconButton><PlusButtonBlue /></IconButton>}
    >
      <NarrowMenuItem primaryText="Create Company" onClick={this.handleOpenCreateCompany} />
      <NarrowMenuItem primaryText="Create Group" onClick={this.handleOpenCreateGroup} />
    </IconMenu>);
  }

  renderTableRow = (row, i, rows) => {
    return (<GroupRow
      key={row.id}
      row={row}
      checkRow={this.checkRow.bind(this, row.id)}
      onSelect={this.handleContactSelect}
      onExpand={this.handleExpandGroup.bind(this, row.id)}
      className={cx()}
      readonly={this.props.readonly}
      withCheckbox={this.props.withCheckbox}
      selectedContactId={this.props.contactId}
    />);
  }

  render() {
    return super.render();
  }
}

export const CompaniesTableConnected = connect(state => ({ contactList: state.contacts.companyList, counters: state.contacts.counters, user: state.login.user, tagCategoriesByName: state.login.tagCategoriesByName, lastFilters: state.login.lastFilters }), { getContactList: getCompanyList, getContactDetails: getCompanyDetails, createContact: createCompany, checkContact, checkAll, expandGroup, getAccountFilters })(withStyles(s)(CompaniesTable));
