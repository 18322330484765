import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Port.scss';
import { connect } from 'react-redux';

import Map from '../Regions/Map';
import Page from '../Page/Page';
import Formsy from 'formsy-react';
import CountrySelect from '../Common/CountrySelect';
import RaisedButton from '../Common/RaisedButton';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import FormsyText from '../Common/FormsyText';
import Select from '../Common/Select';
import MenuItem from 'material-ui/MenuItem';
import ConfirmDialog from '../Common/ConfirmDialog';

import * as portActions from '../../actions/port';

import history from '../../core/history';
import { number } from '../../core/format';
import { loadMap } from '../../core/utils';
import { debounce } from 'formsy-material-ui/lib/utils';
import Port from '../../core/api/Port';

import { timezoneList } from './settings';
import PortListSection from './PortListSection';
import Link from '../Link/Link';

const Form = Formsy.Form;

const labelStyle = { fontSize: '12px', lineHeight: '12px', color: '#999999', top: '46px', transform: 'scale(1) translate(0px, 0px)' };
const labelShrinkStyle = { transform: 'scale(1) translate(0px, -20px)' };
const labelIconStyle = {top: '12px', fill: '#B2B2B2', width: '34px'}



const mapStyles = [
  {
      "featureType": "all",
      "elementType": "labels.text.fill",
      "stylers": [
          {
              "color": "#6c747d"
          }
      ]
  },
  {
      "featureType": "all",
      "elementType": "labels.text.stroke",
      "stylers": [
          {
              "visibility": "on"
          },
          {
              "color": "#13263c"
          },
          {
              "weight": 2
          },
          {
              "gamma": "1"
          }
      ]
  },
  {
      "featureType": "all",
      "elementType": "labels.icon",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "administrative",
      "elementType": "geometry",
      "stylers": [
          {
              "weight": 0.6
          },
          {
              "color": "#223347"
          },
          {
              "gamma": "0"
          }
      ]
  },
  {
      "featureType": "landscape",
      "elementType": "geometry",
      "stylers": [
          {
              "color": "#324558"
          },
          {
              "weight": "10"
          },
          {
              "lightness": "-10"
          }
      ]
  },
  {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
          {
              "color": "#2f3f51"
          }
      ]
  },
  {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
          {
              "color": "#283b51"
          }
      ]
  },
  {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
          {
              "color": "#182b40"
          },
          {
              "lightness": "0"
          }
      ]
  },
  {
      "featureType": "transit",
      "elementType": "geometry",
      "stylers": [
          {
              "color": "#304358"
          }
      ]
  },
  {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
          {
              "color": "#26374c"
          },
          {
              "lightness": "-10"
          }
      ]
  }
]

export class AddPortForm extends PureComponent {
  static defaultProps = {
    handleCloseAddPort: () => history.push(`/port/`),
  }
  static contextTypes= {
    store: PropTypes.object,
    blockTransition: PropTypes.func,
  }

  state = {
    isFormChanged: false
  };

  componentDidMount() {
    loadMap().then(() => {
      this.map = this.map || new google.maps.Map(document.getElementById('create-port-map'), {
        draggableCursor: 'crosshair',
        center: { lat: 34.53996901, lng: -41.52546125 },
        zoom: 3,
        streetViewControl: false,
        styles: mapStyles,
      });
      google.maps.event.addListener(this.map, 'click', this.addMarker);
    });
  }

  handleStateChange = () => {
    if (!this.state.isFormChanged) {
      this.setState({ isFormChanged: true });
      if (!this.unblock) {
        this.unblock = this.context.blockTransition('Are you sure you want to leave this page?');
      }
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      this.unblock && this.unblock();
      this.unblock = null;
    }
  }

  handleValidSubmit = (port) => {
    if(this.state.loading) {
      return;
    }
    this.setState({ submitted: true });
    if (!port || !this.state.position) {
      return;
    }
    port.coordinates = [this.state.position.lng(), this.state.position.lat()];
    port = { ...{ portLimitations: {}, liftsCranes: {}, facilities: {}, serviceProviders: [], warehouses: {} }, ...port };
    this.state.loading = true;
    this.setState({ loading: true });
    this.context.store.dispatch(portActions.create(port)).then((res) => {
      this.setState({ loading: false });
      if (res.status === 200) {
        //this.props.handleClose(undefined, undefined, res.data);
        this.unblock && this.unblock();
        this.props.handleCloseAddPort();
      }
    });
  };

  handleInvalidSubmit = () => {
    this.handleValidSubmit();
  }

  addMarker = (e) => {
    const marker = this.state.marker || new google.maps.Marker({
      position: e.latLng,
      map: this.map,
      icon: '/images/port-marker.svg'
    });
    marker.setPosition(e.latLng);
    this.setState({ marker: marker, position: e.latLng });
    this.handleStateChange();
  }

  checkIfUnLoCodeExist = debounce(async (unLoCode) =>{
    if(unLoCode?.length !== 5){
      this.setState({isPortExist: false})
      return
    }
    try {
      const res = await Port.checkExistingUnLoCode(unLoCode)
      if(res.data?.exists) this.setState({isPortExist: true, sefName: res.data.port?.seo?.sefName})
      else this.setState({isPortExist: false})
    } catch (error) {
      console.log(error);
    }
  }, 300)


  render(){
    console.log(timezoneList);
    return (
    <div className={s.port_add}>
      {this.state.confirmTransitionDialog
        ? this.state.confirmTransitionDialog
        : null}
      <Form ref="form" style={{height: '100%'}} noValidate
        onValidSubmit={this.handleValidSubmit}
        onInvalidSubmit={this.handleInvalidSubmit}
        onChange={this.handleStateChange}
      >
        <div className={s.port_add_header}>
          <p>ADD NEW PORT</p>
          <div>
            <RaisedButton
              label="Add"
              disableTouchRipple
              disableFocusRipple
              primary
              type="submit"
              style={{
                minWidth: '80px',
                marginRight: '17px'
              }}
              buttonStyle={{
                width: '80px',
                height: '28px',
                lineHeight: '26px',
              }}
              labelStyle={{
                padding: '0',
                fontSize: '11px'
              }}
            />
            <CloseIcon
              onClick={this.props.handleCloseAddPort}
              style={{
                cursor: 'pointer',
              }}
            />
          </div>
        </div>
        <div className={s.port_add_content}>
          <div className={s.port_add_inputs}>
            <div style={{width: '183px', marginRight: '16px'}}>
              <FormsyText
                floatingLabelStyle={labelStyle}
                floatingLabelShrinkStyle={labelShrinkStyle}
                floatingLabelText="Port Name*"
                fullWidth
                required
                requiredError="required"
                name="name"
              />
            </div>
            <div style={{width: '100px', marginRight: '16px', position: 'relative'}}>
              <FormsyText
                fullWidth
                floatingLabelText="UN LOCODE"
                onChange={(e,val)=>this.checkIfUnLoCodeExist(val)}
                name="unLoCode"
                invalid={this.state.isPortExist}
                errorText={this.state.isPortExist ? 'UNLOCODE exist' : ''}
                floatingLabelStyle={labelStyle}
                floatingLabelShrinkStyle={labelShrinkStyle}
                validations="minLength:5,maxLength:5"
                validationError="5 characters"
              />
              {this.state.isPortExist && (
                <div style={{position: 'absolute', padding: '8px 12px', fontSize: '14px', fontWeight: '400', width: '300px', background: '#26374C', borderRadius: '8px', color: '#fff', zIndex: 1, marginTop: '12px'}}>
                  <div style={{marginBottom: '6px'}}>Since this port already exists, you can edit it.</div>
                  <Link target='_blank' to={`/port/${this.state.sefName}/edit`}>Edit port</Link>
                </div>
              )}
            </div>
            <div style={{width: '100px', marginRight: '16px'}}>
              {/* fullWidth is for the input, autoWidth is for dropdown menu */}
              <Select
                fullWidth
                autoWidth
                floatingLabelStyle={labelStyle}
                floatingLabelShrinkStyle={labelShrinkStyle}
                iconStyle={labelIconStyle}
                hintText="Time Zone"
                floatingLabelText="Time Zone"
                name="timezone"
                maxHeight={271}
                dropDownMenuProps={{ menuStyle: { maxWidth: '136px' } }}
              >
                {
                  timezoneList.map((tz) => (
                    <MenuItem key={tz} value={tz} primaryText={tz} />
                  ))
                }
              </Select>
            </div>
            <div style={{width: '183px'}}>
              <CountrySelect
                floatingLabelStyle={labelStyle}
                floatingLabelShrinkStyle={labelShrinkStyle}
                iconStyle={labelIconStyle}
                floatingLabelText="Country*"
                fullWidth
                noFlag
                name="country"
                className="country-select"
                required
                requiredError="required"
              />
            </div>
          </div>
          <div className={s.port_add_coords}>
            <span>Latitude / Longitude:</span> {this.state.position ? <span style={{color: '#333333'}}>{number(this.state.position.lat(), undefined, undefined, 3).toString().replace(',', '.')}° / {number(this.state.position.lng(), undefined, undefined, 3).toString().replace(',', '.')}° </span> : <span style={{color: 'var(--text-links)', fontWeight: 400, textTransform: 'initial'}}>Set marker on the map</span>}
            <div style={{ display: this.state.submitted && !this.state.position ? 'block' : 'none' }}>
              <hr style={{borderTop: '2px solid  rgb(244, 67, 54)', margin: '4px 0 0'}} />
              <span style={{color: 'rgb(244, 67, 54)', fontSize: '12px', textTransform: 'initial'}}>required</span>
            </div>
          </div>
        </div>
        <div className={s.port_add_map}>
          <div id="create-port-map" style={{height: '100%'}}/>
        </div>
      </Form>
    </div>
    )
  }
}
export const StyledAddPortForm = withStyles(s)(AddPortForm);



class PortListPage extends PureComponent {

  static propTypes = {
    getList: PropTypes.func,
    portList: PropTypes.object,
  };

  static defaultProps = {
    baseUrl: '/port/',
  }

  state = {};

  render() {
    const allPorts = this.props.portList.data;
    // console.log('STATE', this.state)

    return (<Page headerTitle="PORTS">
      <div className={s.port_wrapper}>
        <PortListSection addPort={this.props.addPort} searchEnabled addPortEnabled />
        {
          this.props.addPort && this.props.user ?
            <AddPortForm />
          :
          <div className={s.port_map}>
            <div className={s.port_map_header}>MAP</div>
            <div className={s.port_map_body}>
              <Map styles={mapStyles} zoom={3} allPorts={allPorts} portMap key={allPorts.length}/>
            </div>
          </div>
        }
      </div>
    </Page>);
  }
}

const mapStateToProps = function(state) {
  return {
    ...state.port,
    user: state.login.user
  }
}

export default connect(mapStateToProps, {})(
  withStyles(s)(PortListPage),
);
