
import React, { PureComponent } from 'react';
import Dialog from '../Common/Dialog';
import ExtendedForm from '../Common/ExtendedForm';
import CustomCheckbox from '../Common/CustomCheckbox';
import RaisedButton from '../Common/RaisedButton';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { savePreferences } from '../../actions/login';
import Link from "../Link";
import MainDeckIcon from '../Icons/Main_Deck';
import LockIcon from 'material-ui/svg-icons/action/lock';
import CargoIcon from '../Icons/Cargo';
import DocumentsIcon from '../Icons/DocumentsIcon';

const STARTDATE_ABOUT_TD = new Date('2023-03-23T16:03:50.788Z');

const dialogs = [{
  id: 'not_connected_to_mail_gate',
  title: 'YOUR COMPANY NOT CONNECTED YET',
  body: function (props) {
    return <div>
      <p>At the core of shipnext is the advanced algorithm to process shipping emails and organize them on a trading desk.</p>
      <p>Cargo, orders and open positions that arrive in your inbox are private and visible to you or your defined team only! Unless you decide to share them with the market.</p>
      <p>Shipnext integrates with Gmail, Sedna, LgMar and other email servers that allow <a download href="/guide_pdfs/SHIPNEXT_Connect_for_Gmail.pdf" >Add-Ons</a> for other email services, we can connect using IMAP.</p>
      <p>For more details please visit <a download href="/guide_pdfs/SHIPNEXT_Connect_for_IMAP.pdf" >this page</a>, or contact our <a target="_blank" href="/help">Support team</a>.</p>
    </div>;
  },
  visibleFn: (props) => {
    if(props.path !== '/main-deck') {
      return false;
    }
    if(props.user.mailGateEnabled === true || props.user.extension?.endAt) {
      return false;
    }
    const d = props.user.accountPreferences.marketingDialogs?.find(d => d.id === 'not_connected_to_mail_gate');
    if(!d || new Date(d.nextDisplayDate) < new Date()) {
      return true;
    }
    return false;
  },
  postponeDays: 7,
  dialogProps: {},
  displayFooter: true,
},
  {
    id: 'about_trading_desk',
    title: '',
    body: function (props) {
      return <AboutTdBodyWrapper>
        <div>
          <div><div style={{width: '24px', height: '24px'}}><MainDeckIcon backColor={"#D2D2D2"} opacity={1} /></div></div>
          <div>
            <p>Trading Desk is designed for freight search (Cargo-to-Ship / Ship-to-Cargo matching), freight-tendering and freight negotiations. Start by adding the Cargo using the feature, sending it to <a href={"mailto:fix@shipnext.com"}>fix@shipnext.com</a>  or connecting your Shipment data using API</p>
            <p>The Cargo or Orders are marked differently depending on their source:</p>
            <ul className="cargo_type_ul" style={{listStyleType: "none", margin: 0, padding: 0}}>
              <li><span className="bullet bullet_my"></span>&nbsp;- Cargo/Orders added by your company</li>
              <li><span className="bullet bullet_received" ></span>&nbsp;- Cargo/Orders received via Email or API from your customers</li>
              <li><span className="bullet bullet_market" ></span>&nbsp;- Cargo/Orders circulated on the Market</li>
            </ul>
          </div>
        </div>
        <div>
          <div><LockIcon style={{color:"#D2D2D2"}} /></div>
          <div>
            <p>You can control who has access to your Cargo/Orders by creating your <Link to={"/settings"}>Whitelist</Link></p>
          </div>
        </div>
      </AboutTdBodyWrapper>;
    },
    visibleFn: (props) => {
      if (props.path !== '/main-deck') {
        return false;
      }
      if (!props.user.emailVerifiedAt || new Date(props.user.emailVerifiedAt) < STARTDATE_ABOUT_TD) {
        return false;
      }
      const d = props.user.accountPreferences.marketingDialogs?.find(d => d.id === 'about_trading_desk');
      if (!d || new Date(d.nextDisplayDate) < new Date()) {
        return true;
      }
      return false;
    },
    postponeDays: 36500,
    dialogProps: { overlayStyle: { display: 'none' }, style: { width: '50vw', transition: 'none', top: '144px', padding: '0', zIndex: 1199 }, className: "dialog_over_left_side_table" },
    displayFooter: false,
  },
  {
    id: 'about_cargo_monitor',
    title: '',
    body: function (props) {
      return <AboutTdBodyWrapper>
        <div>
          <div><div style={{width: '24px', height: '24px'}}><CargoIcon fill={"#D2D2D2"} opacity={1} /></div></div>
          <div>
            <p>Cargo Monitor is designed for tracking of freight requests, quotations and freight negotiation</p>
            <p>The Cargo or Orders are marked differently depending on their source:</p>
            <ul className="cargo_type_ul" style={{listStyleType: "none", margin: 0, padding: 0}}>
              <li><span className="bullet bullet_my"></span>&nbsp;- Cargo/Orders added by your company</li>
              <li><span className="bullet bullet_received" ></span>&nbsp;- Cargo/Orders received via Email or API from your customers</li>
              <li><span className="bullet bullet_market" ></span>&nbsp;- Cargo/Orders circulated on the Market</li>
            </ul>
            <br/>
          </div>
        </div>
      </AboutTdBodyWrapper>;
    },
    visibleFn: (props) => {
      if (props.path !== '/my/cargo') {
        return false;
      }
      if (!props.user.emailVerifiedAt || new Date(props.user.emailVerifiedAt) < STARTDATE_ABOUT_TD) {
        return false;
      }
      const d = props.user.accountPreferences.marketingDialogs?.find(d => d.id === 'about_cargo_monitor');
      if (!d || new Date(d.nextDisplayDate) < new Date()) {
        return true;
      }
      return false;
    },
    postponeDays: 36500,
    dialogProps: { overlayStyle: { display: 'none' }, style: { width: '50vw', transition: 'none', top: '144px', padding: '0', zIndex: 1199 }, className: "dialog_over_left_side_table" },
    displayFooter: false,
  },
  {
    id: 'about_documents',
    title: '',
    body: function (props) {
      return <AboutTdBodyWrapper>
        <div>
          <div><div style={{width: '24px', height: '24px'}}><DocumentsIcon fill={"#D2D2D2"} opacity={1} /></div></div>
          <div>
            <p>Documents - Charter Parties, Booking Notes, Bill of Lading - are created automatically following the completed freight negotiations, or manually, and connected to Accounting software.</p>
            <p>The Cargo or Orders are marked differently depending on their source:</p>
          </div>
        </div>
        <div>
          <div><LockIcon style={{color:"#D2D2D2"}} /></div>
          <div>
            <p>Terms and Conditions saved in the Contracts are stored privately, and can be reused in future shipments, bidding, negotiations.</p>
          </div>
        </div>
      </AboutTdBodyWrapper>;
    },
    visibleFn: (props) => {
      if (props.path !== '/contracts/voyage') {
        return false;
      }
      if (!props.user.emailVerifiedAt || new Date(props.user.emailVerifiedAt) < STARTDATE_ABOUT_TD) {
        return false;
      }
      const d = props.user.accountPreferences.marketingDialogs?.find(d => d.id === 'about_documents');
      if (!d || new Date(d.nextDisplayDate) < new Date()) {
        return true;
      }
      return false;
    },
    postponeDays: 36500,
    dialogProps: { overlayStyle: { display: 'none' }, style: { width: '50vw', transition: 'none', top: '144px', padding: '0', zIndex: 1199 }, className: "dialog_over_left_side_table" },
    displayFooter: false,
  }
];


 class AutoDialogs extends PureComponent {
  state = { mounted: false}
  componentDidMount() {
    setTimeout(() => {
      this.setState({ mounted: true });
    }, 2500);
  }

  onClose= async (dialog, dontRemind) =>  {
    let dialogs = this.props.user.accountPreferences.marketingDialogs || [];
    const nextDisplayDate = new Date();
    nextDisplayDate.setDate(nextDisplayDate.getDate() + dialog.postponeDays);
    if(dontRemind) {
      nextDisplayDate.setYear(nextDisplayDate.getFullYear() + 100);
    }
    dialogs = dialogs.filter(d => d.id !== dialog.id);
    dialogs.push({ id: dialog.id, nextDisplayDate });
    await this.props.savePreferences({ marketingDialogs: dialogs });
 }

  render() {
  if (!this.state.mounted || !this.props.user || !this.props.user.emailVerified || !this.props.user.approved){
    return null;
  }
  const dialog = dialogs.find(d => d.visibleFn(this.props));
  if (!dialog) {
    return null;
  }
  return <BaseDialog key={dialog.id} dialog={dialog} {...this.props} onClose={this.onClose} />;
}
}

class BaseDialog extends PureComponent {
  state = { dontRemind: false };
  handleClose = () => {
    this.props.onClose(this.props.dialog, this.state.dontRemind);
  }

  onDontRemindChange = (checked) => {
    this.setState({ dontRemind: checked });
  }

  render() {
    const dialog = this.props.dialog;
    const Body = dialog.body;
   return <Dialog
     titleClassName={'dialog_small_header'}
     contentStyle={{maxWidth: '620px'}}
     title={dialog.title}
     open handleClose={this.handleClose}
     {...dialog.dialogProps} >
          <DialogBodyWrapper>
            <ExtendedForm>
              <Body />
              {dialog.displayFooter ? (
                <footer>
                  <CustomCheckbox checked={this.state.dontRemind} onCheck={this.onDontRemindChange} label="Never remind me again" />
                  <RaisedButton onClick={this.handleClose} primary label="OK" />
                </footer>
              ) : null}
            </ExtendedForm>
          </DialogBodyWrapper>
   </Dialog>
  }
}

const AboutTdBodyWrapper = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin-top: -12px;
  color: var(--text-medium);
  a {
    text-decoration: underline;
  }
  p {
    font-size: 16px;
    margin: 12px 0;
    color: var(--text-medium);
  }
  >div {
    display: flex;
    >div:first-child {
      margin: 12px 12px 0 0;
      width: 24px;
      height: 24px;
    }
  }
  .cargo_type_ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    > li {
      display: flex;
      align-items: center;
    }
  }
`

const DialogBodyWrapper = styled.div`
  max-width: 620px;
  padding: 0 16px;
  font-size:12px;
  color: var(--text-dark);
  .custom-checkbox {
    label{
      font-size: 14px;
    }
    width: 220px !important;
  }
  footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

  }

  .bullet {
    display: inline-block;
    height: 8px;
    width: 8px;
    border-radius: 4px;
    &_my {
      background: #75C422;
    }
    &_received {
      background: #82C3FF;
    }
    &_market {
      border: 1px solid #B2B2B2;
    }
  }

`

export default connect(state => state.login, { savePreferences })(AutoDialogs);
