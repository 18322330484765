import React from 'react';
import Layout from '../../components/Layout';
import EmailsPage from '../../components/Emails/EmailsPage';
import AllFleetSection from '../../components/Vessel/AllFleet/allFleetSection';
import PortListSection from '../../components/Port/PortListSection';
import {getAccountFilters} from "../../actions/login";
import FleetOrPortsSection from '../../components/Emails/FleetOrPortsSection';

function parseActionProps(params) {
  if (params.action.indexOf("?") === -1) {
    return;
  }
  // eslint-disable-next-line prefer-const
  let [action, props] = params.action.split("?", 2);
  const it = (new URLSearchParams(props)).entries();
  props = {};
  for (const [key, val] of it) {
    if (props[key] !== undefined) {
       if (!Array.isArray(props[key])) {
         props[key] = [props[key]];
       }
       if (val) {
         props[key].push(val);
       }
    } else {
      props[key] = val;
    }
  }
  params.action = action;
  params[`${action}Props`] = props;
  return params;
}

export default {
  path: '/emails',
  children: [
    {
      path: '/:emailId/:action/---/:entityId?/:entityAction?',
      async action({ params, next, baseUrl, token, store, hash, ...rest }) {
        if (params.emailId === '-'){
          params.emailId = undefined;
        }
        parseActionProps(params);
        if(params.entityId?.length < 3) {
          delete params.entityId;
        }
        if (params.entityId === 'create') {
          params.entityAction = 'create';
          delete params.entityId;
        }
        const rightSection = {
          component: <FleetOrPortsSection {...params} detailsBaseUrl={'/emails/-/-/---/'} baseUrl={'/emails/-/-/---/'} hash={hash}/>
        }
        try {
          if ( (!rest.previousPath || rest.pathname.slice(12) !== rest.previousPath.slice(12))) {
            await store.dispatch(getAccountFilters(token));
          }
        } catch (e) {
          console.error(e);
        }
        return {
          title: 'SHIPNEXT - Shipping Marketplace and Transportation Platform',
          component: (
            <Layout>
              <EmailsPage {...params} rightSection={rightSection}/>
            </Layout>
          ),
          public: false,
        };
      },
      // children: [{
      //   path: '/all-fleet/:vesselId?/:action?/:reserved?',
      //   async action({ params, hash, baseUrl, path, store, token, ...rest }) {
      //     if (params.vesselId === 'create') {
      //       params.action = 'create';
      //       delete params.vesselId;
      //     }
      //     const _baseUrl = baseUrl + '/all-fleet/';
      //     const links = allFleetNavLinks.map( l => ({ ...l, url: baseUrl + l.url }));
      //     let filtersFetched = false;
      //     try {
      //       if (!rest.previousPath || rest.previousPath.indexOf('all-fleet') === -1) {
      //         await store.dispatch(getAccountFilters(token));
      //         filtersFetched = true;
      //       }
      //     } catch (e) {
      //       console.error(e);
      //     }
      //     return {
      //       name: 'all-fleet',
      //       title: 'FLEET',
      //       component: <AllFleetSection baseUrl={_baseUrl} detailsBaseUrl={_baseUrl} hash={hash} {...params}
      //                                   create={params.action === 'create'} navLinks={links} pickThemeFrom="vesselList" />,
      //       filtersFetched,
      //     }
      //   },
      // },
      //   {
      //     path: '/port/:portId?/:action?/:reserved?',
      //     async action({ params, hash, baseUrl }) {
      //       const _baseUrl = baseUrl + '/port/';
      //       if(params.portId?.length < 3) {
      //         delete params.portId;
      //       }
      //       const links = portsNavLinks.map( l => ({ ...l, url: baseUrl + l.url }));
      //       return {
      //         name: 'ports',
      //         title: 'PORTS',
      //         component: <PortListSection baseUrl={_baseUrl} addPortEnabled={false} navLinks={links} {...params}/>,
      //       }
      //     },
      //   },
      // ],
    },
  ],
};
