import React, { PureComponent } from 'react';
import history from '../../../core/history';
import { defaultSortField, sortFields } from './sort';
import Table from './Table';
import VesselEdit from '../../Monitor/Edit/VesselEdit';
import LIFO from '../../Common/LIFO';
import { connect } from 'react-redux';
import { changeCargoStyle, changeVesselStyle, updateVesselsList } from '../../../actions/monitor';
import { eraseVesselsList, getVesselsList } from '../../../actions/allFleet';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './AllFleet.scss';
import m from '../../Monitor/Monitor.scss';
import t from '../../Monitor/TableNew.scss';
import { getVessel } from '../../../actions/vessel';
import VesselOpenedDetails from '../../Monitor/VesselOpenedDetails';
import {getVesselPublicHref} from "../../../core/utils";
import { ThemeProvider } from 'styled-components';

class AllFleetSection extends PureComponent {

  static defaultProps = {
    baseUrl: '/fleetmonitor',
    detailsBaseUrl: '/vessel/',
    limit: 50,
  }

  state = {
    loading: true,
    isMoreLoading: false,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.vesselId !== this.props.vesselId) {
      if (nextProps.vesselId) {
        this.fetchVessel(nextProps.vesselId);
      } else if (this.props.vesselId) {
        this.clearSelectedVessel();
      }
    }
  }

  componentDidMount() {
    if (this.props.vesselId) {
      this.fetchVessel(this.props.vesselId);
    }
    this.fetchFleet()
  }


  fetchVessel = async (idOrImo) => {
    const res = await this.props.getVessel(idOrImo);
    this.setState({ selectedVessel: res.data });
  }

  clearSelectedVessel = () => {
    this.setState({ selectedVessel: null });
  }


  selectVessel = vessel => {
    if (!vessel) return;
    history.push(getVesselPublicHref(this.props.detailsBaseUrl, vessel), undefined, undefined, {saveSearchParams: true});
  }

  closeVessel = () => history.push(this.props.baseUrl, undefined, undefined, {saveSearchParams: true});

  closeEditingVessel = () => {
    const id = this.props.vesselId;

    let url = `${this.props.baseUrl}`;

    if (id) {
      url += id;
    }
    history.push(`${url}`);
  }
  prepareRequestFilters = (newFilters) => {
    let { user, filters, sort, limit, isHidden } = this.props;

    filters = {
      ...filters,
      ...newFilters,
    };

    if (!sort?.value && user) {
      sort = defaultSortField;
    }

    if (filters?.search?.length === 0) {
      Reflect.deleteProperty(filters, 'search');
    }
    return {
      filters,
      sort,
      limit,
      isHidden,
    };
  }

  fetchFleet = async (options = {}) => {
    try {
      this.setState({
        loading: true,
      });
      const filters = { ...this.prepareRequestFilters(), ...options };

      await this.props.getVesselsList({
        ...filters,
      });
      this.props.onFilter?.(filters.filters);
    } catch (error) {
      // handled by middleware: check fetchFailed flag in reducer.
      console.error(error);
    } finally {
      this.setState({
        loading: false,
      });
    }
  }
  loadMoreFleet = async (page, options) => {
    this.setState({
      isMoreLoading: true,
    });
    try {
      await this.props.getVesselsList({
        isHidden: this.props.isHidden,
        page: page || 1,
        ...this.prepareRequestFilters(),
        ...options,
      });

    } catch (error) {
      console.log(error);
    } finally {
      this.setState({
        isMoreLoading: false,
      })
    }
  }

  handleTableLoadVessels = (page, options = {}) => {
    this.loadMoreFleet(page, options);
  }

  addCreatedVesselToFleet = () => {
    this.fetchFleet();
    this.closeEditingVessel();
  }
  changeTheme = (...args) => {
    if(this.props.pickThemeFrom === 'vesselList') {
      this.props.changeVesselStyle(...args);
    } else {
      this.props.changeCargoStyle(...args);
    }
  }

  render() {
    const { baseUrl, navLinks } = this.props;
    const { loading, isMoreLoading } = this.state;
    const isDark = this.props.dark;
    const ship = this.state.selectedVessel?.vessel || this.state.selectedVessel;
    const stack = [
      <Table
        keyId={1}
        handleLoadMore={this.handleTableLoadVessels}
        handleLoadRows={this.fetchFleet}
        sortFields={sortFields}
        changeTheme={this.changeTheme}
        baseUrl={baseUrl}
        isDark={isDark}
        loading={loading}
        isMoreLoading={isMoreLoading}
        selectVessel={this.selectVessel}
        navLinks={navLinks}
        detailsBaseUrl={this.props.detailsBaseUrl}
        renderTabs={this.props.renderTabs}
      />,
    ];
    if(ship) {
      stack.push(
        <VesselOpenedDetails selectEntity="vessels" selectedVessel={ship} basePath={this.props.baseUrl} myfleet={true} handleVesselClosed={this.closeVessel} showSearch showDescription key={ship._id} keyId={2} />
      )
    }
    if (this.props.create || (this.props.action === 'edit' && ship)) {
      stack.push(
        <VesselEdit
          keyId={3}
          closeEditingVessel={this.closeEditingVessel}
          attachRef={instance => this.editInstance = instance}
          myfleet
          basePath={`${baseUrl}`}
          onChangedVessel={this.addCreatedVesselToFleet}
          vessel={ship}
          edit={this.props.action === 'edit'}
        />,
      )
    }
    return (
      <ThemeProvider theme={{ name: (isDark && 'dark_theme') || 'white_theme' }}>
        <LIFO>
          {
            stack
          }
        </LIFO>
     </ThemeProvider>
     );
  }
}

export default connect((state, ownProps) => ({ ...state.allFleet, user: state.login.user, dark: state.monitor[ownProps.pickThemeFrom || 'cargoList'].dark }), {
  changeCargoStyle,
  changeVesselStyle,
  getVesselsList,
  eraseVesselsList,
  updateVesselsList,
  getVessel,
})(withStyles(s, m, t)(AllFleetSection));
