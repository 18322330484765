import createBrowserHistory from 'history/createBrowserHistory';

// Navigation manager, e.g. history.push('/home')
// https://github.com/mjackson/history

const history =
  process.env.BROWSER &&
  createBrowserHistory({
    getUserConfirmation(message, callback) {
      if (window.confirmTransition) {
        window.confirmTransition(callback);
      } else {
        callback(true);
      }
    },
  });
export default history;
const inMemory = [];
if (history) {
  history.listen((location, action) => {
    switch (action) {
      case 'PUSH':
        inMemory.push(location);
        break;
      case 'POP':
        inMemory.pop();
        break;
    }
  });

  history.canBack = function () {
    return inMemory.length > 0;
  };

  history.safeBack = function (fallbackUrl) {
    if (history.canBack()) {
      history.goBack();
    } else {
      history.push(fallbackUrl);
    }
  };
  history.getPrevious = function () {
    return inMemory[inMemory.length - 2];
  };
  const push = history.push.bind(history);
  const _replace = history.replace.bind(history);

  history.push = (path, state, replace = false, options={}) => {
    if (path.indexOf('/$') !== -1) {
      const currentPathParts = window.location.pathname.split('/');
      path = path.replace(/\$(\d)/g, (m, p1) => currentPathParts[parseInt(p1, 10)] || '-');
    }
    if (path.slice(-3) === '---') { //TODO support query params and hash
      if (window.location.pathname.indexOf('/---/') !== -1) {
        const splitted = window.location.pathname.split('/---/');
        if (path[path.length - 1] === '/') {
          path = path.slice(0, -1);
        }
        path = path + '/' + splitted[1];
      }
    }
    if(options.saveSearchParams) {
      const search = getSearchParamsString();
      path = path + search;
    }
    if (replace) {
      _replace(path, state);
    } else {
      push(path, state);
    }
  }
  history.setSearchParams = (params) => {
    const searchParamsString = getSearchParamsString(params);
    history.push(window.location.pathname + searchParamsString);
  };
}

function getSearchParamsString(params={}){
  const searchParams = new URLSearchParams(window.location.search);

  Object.entries(params).forEach(([key, value]) => {
    if (value === null || value === undefined) {
      searchParams.delete(key);
    } else {
      searchParams.set(key, value);
    }
  });

  const newSearch = searchParams.toString() ? `?${searchParams.toString()}` : "";
  return newSearch;
}
