import React, { PureComponent } from 'react';
import PubSub from 'pubsub-js';
import DialogHalfScreen from "../Common/DialogHalfScreen";
import { ContactDetailsConnected } from "./ContactDetails";
import history from "../../core/history";

class GlobalContactDetailsDialog extends PureComponent {
  state = { key: 0};
  componentDidMount() {
    PubSub.subscribe('GlobalContactDetailsDialog', this.onMessage);
  }
  componentWillReceiveProps(nextProps) {
    if (this.state.opened && nextProps.path !== this.props.path){
      const path = this.props.path.split('/');
      const nextPath = nextProps.path.split('/');
      if (path[1] !== nextPath[1]) {
        this.setState({ opened: false });
      }
    }
  }

  componentWillUnmount() {
    PubSub.unsubscribe(this.onMessage);
  }


  onMessage = (topic, { contact, position = 'right', createChatProps }) => {
    this.setState({ opened: true, position, contact, createChatProps, key: this.state.key + 1 });
  }

  onClose = () => {
    this.setState({ opened: false, action: null, contact: null, createChatProps: null });
  }
  onStartEdit = () => {
    this.setState({ action: 'edit' });
  };

  onCloseEdit = ({ action, id }) => {
    const newState = { action: null };
    if (!this.state.contact.id) {
      if (id) {
        newState.contact = { ...this.state.contact, id };
      } else {
        newState.opened = false;
      }
    }
    this.setState(newState);
  };

  onCloseView = () => {
    this.onClose();
  };

  companyLinkPropsGenerator = (c) => ({ to: `/contacts/company:${c.id}/-?contactsTab=companies`, target: '_blank' });

  getHistoryBlockPredicate = (c) => (contact) => false;

  render() {
    if (!this.state.opened) {
      return null;
    }

    return (
        <DialogHalfScreen key={this.state.key} open position={this.state.position} >
          <ContactDetailsConnected
            contactId={this.state.contact.id}
            draft={this.state.contact}
            onCloseEdit={this.onCloseEdit}
            onCloseView={this.onCloseView}
            onStartEdit={this.onStartEdit}
            action={this.state.action}
            companyLinkPropsGenerator={this.companyLinkPropsGenerator}
            getHistoryBlockPredicate={this.getHistoryBlockPredicate}
            isGlobalPopover
          />
        </DialogHalfScreen>);
  }
}

export default GlobalContactDetailsDialog;
