import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import s from './Home.scss';
import Link from '../Link';
import Handshake from '../Icons/Handshake';
import RoundThemableButton from '../Common/RoundThemableButton';
import MainPageHero from './MainPageHero';
import Main_Deck from '../Icons/Main_Deck';

function Buttons(props) {
  return (
    <div className={s.buttons_wrapper}>
      <div className={cx(s.btn_wrapper, s.add_cargo)}>
        <Link className={s.round_btn} to={'/cargo/general/create'}>
          <span className={s.arrow}>+</span>
          <span className={s.text}>Request Freight</span>
        </Link>
        <p>or send it to <a href="mailto:fix@shipnext.com">fix@shipnext.com</a></p>
      </div>
      <div className={cx(s.btn_wrapper, s.become_supplier)}>
        <Link className={s.round_btn} to="/register">
          <span className={s.arrow}>
            <Handshake></Handshake>
          </span>
          <span className={s.text}>become a supplier</span>
        </Link>
      </div>
       <div className={cx(s.btn_wrapper, s.market)}>
        <RoundThemableButton className={s.round_btn} component={Link} to="/main-deck">
          <span className={s.arrow}>
          <Main_Deck opacity='1'/>
          </span>
          <span className={s.text}>get started</span>
        </RoundThemableButton>
      </div>
    </div>
  )
}

class FirstScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textOpened: false,
    };
  }

  handleShowText = () => {
    this.setState({textOpened: !this.state.textOpened});
  };

  render() {
    return (
      <div>
        <div className={cx(s.wrapper_content, s.marketplace)}>
          <div className={s.container_in_tab}>
            <div className={s.input_container_in_tab}>
              <div className={s.left_wrapper_about}>
                <div className={cx(s.wrapper_title_video, s.wrapper_background_mob, s.without_border)}>
                  <MainPageHero>
                    <Buttons />
                  </MainPageHero>
                </div>
                <div className={s.bg_for_mob}>
                  <div className={s.cargo_add_link}>
                    <span className={s.mobile_subtitle}>
                      manage your shipping data,<br /> trade and automate work-flows
                    </span>
                    <div className={s.mobile_buttons}>
                      <Buttons />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(s)(FirstScreen);
